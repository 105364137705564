import { APICore } from "../api/apiCore"

// Configuracion
const api = new APICore()
const baseApiurl = process.env.REACT_APP_NFI_URL
const baseApiurlUsersEntities = process.env.REACT_APP_AUTH_USERS_URL

//------- ASIGNATION
export const createAsignation = async (asignation = null) => {
  const baseUrl = `${baseApiurl}/assignments`
  try {
    return api.create(`${baseUrl}`, asignation)
  } catch (error) {
    console.error(error)
  }
}

export const getNFIListAsignations = async (params = null) => {
  params = { limit: params?.limit ?? 5, page: params?.page ?? 1, ...params }
  const baseUrl = `${baseApiurl}/assignments`
  if (!params) return await api.get(`${baseUrl}`)
  else return await api.get(`${baseUrl}`, params)
}

export const updateKitsAsignation = async (idAsignarion, kits) => {
  const baseUrl = `${baseApiurl}/assignments/${idAsignarion}/kits`
  try {
    return api.update(`${baseUrl}`, kits)
  } catch (error) {
    console.error(error)
  }
}

export const ChangeStatusAsignation = async (idAsignarion, status) => {
  const baseUrl = `${baseApiurl}/assignments/${idAsignarion}/status`
  try {
    return api.updatePatch(`${baseUrl}`, status)
  } catch (error) {
    console.error(error)
  }
}

//------- DEALERS
export const getAllDealers = async () => {
  const baseUrl = `${baseApiurl}/dealers`
  try {
    return api.get(`${baseUrl}`)
  } catch (error) {
    console.error(error)
  }
}

export const createDealer = async (dealer) => {
  const baseUrl = `${baseApiurl}/dealers`
  try {
    return api.create(`${baseUrl}`, dealer)
  } catch (error) {
    console.error(error)
  }
}

export const getDealerById = async (idDealer) => {
  const baseUrl = `${baseApiurl}/dealers/${idDealer}`
  return await api.get(`${baseUrl}`)
}

export const getValidateDealerExist = async (dealerName, personEmail) => {
  const baseUrl = `${baseApiurl}/dealers/search`
  return await api.get(`${baseUrl}`, { email: personEmail, name: dealerName })
}

export const updateDealerStatus = (username, params) => {
  const baseUrl = baseApiurl + `/dealers/${username}/active`
  return api.updatePatch(baseUrl, params)
}

//------- ENTITIES
export const getAllEntities = async () => {
  const baseUrl = `${baseApiurl}/entities`
  try {
    return api.get(`${baseUrl}`)
  } catch (error) {
    console.error(error)
  }
}

export const createEntity = async (entity) => {
  const baseUrl = `${baseApiurlUsersEntities}/entities`
  try {
    return api.create(`${baseUrl}`, entity)
  } catch (error) {
    console.error(error)
    return null
  }
}

// ---- KITS
export const apiCallKitsList = async () => {
  const baseUrl = `${baseApiurl}/nfis`
  try {
    return await api
      .get(`${baseUrl}`)
      .then((res) => res.data)
      .catch((error) => {
        console.error(error)
        return []
      })
  } catch (err) {
    return []
  }
}

export const getNFIByEmail = (email) => {
  const baseUrl = baseApiurl + `/dealers/by-email?email=${email}`
  return api.get(baseUrl)
}

export const updateDealer = (id, data) => {
  const baseUrl = baseApiurl + `/dealers/${id}`
  return api.update(baseUrl, data)
}

export const getDeliveryAdvance = async (params) => {
  const baseUrl = `${baseApiurl}/kpi/delivery_advance`
  if (params) return await api.get(baseUrl, params)
  else return await api.get(baseUrl)
}

export const getActiveAssignments = async () => {
  const baseUrl = `${baseApiurl}/kpi/active_assignments`
  return await api.get(baseUrl)
}

export const getKitsDelivered = async (params) => {
  const baseUrl = `${baseApiurl}/kpi/kits_delivered`
  if (!params) return await api.get(baseUrl)
  else return await api.get(baseUrl, params)
}

export const getKitsDeliveredByName = async (params) => {
  const baseUrl = `${baseApiurl}/kpi/kits_delivered_by_name`
  if (!params) return await api.get(baseUrl)
  else return await api.get(baseUrl, params)
}

// ---- Register Deliveries
export const getRegisterDeliveries = async (params) => {
  const baseUrl = baseApiurl + `/registerdeliveries`
  if (!params) return await api.get(baseUrl)
  else return await api.get(baseUrl, params)
}

export const getRegisterDelivery = async (id, params) => {
  const baseUrl = baseApiurl + `/registerdeliveries/${id}`
  if (!params) return await api.get(baseUrl)
  else return await api.get(baseUrl, params)
}

export const createDeliveryAsignation = async (asignation = null) => {
  const baseUrl = `${baseApiurl}/registerdeliveries`
  try {
    return api
      .create(`${baseUrl}`, asignation)
      .then((d) => d)
      .catch((err) => null)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const patchDeliveryAsignation = async (id, params, profile) => {
  const baseUrl = `${baseApiurl}/registerdeliveries/${id}`
  try {
    if (!params) return await api.updatePatch(`${baseUrl}`)
    return await api.updatePatch(`${baseUrl}`, params, {
      headers: {
        profile,
      },
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
