// @flow
import { personTypes } from "./constants"
import { Migrant } from "../../pages/persons/models/migrants.model"
import { calcularEdad } from "../../utils/utils"

export interface PersonState {
  home: any[]
  person: {
    family: Migrant[]
    dataPreRegisterFromSearch: any
  }
  detail: {}
}

const INIT_STATE: PersonState = {
  home: [],
  person: {
    family: [],
    dataPreRegisterFromSearch: null,
  },
  detail: {},
}

const Persons = (state = INIT_STATE, action: any): PersonState => {
  switch (action.type) {
    case personTypes.PERSON_INFO:
      return {
        ...state,
      }
    case personTypes.PERSON_INFO_NOT_FOUND_REGISTER:
      return {
        ...state,
        person: {
          ...state.person,
          dataPreRegisterFromSearch: { ...action.payload },
        },
      }
    case personTypes.PERSON_INFO_NOT_FOUND_REGISTER_CLEAR_DATA:
      return {
        ...state,
        person: {
          ...state.person,
          dataPreRegisterFromSearch: null,
        },
      }
    case personTypes.PERSON_CREATE_FAMILY:
      const exist = state.home.some(
        (el: any) => el.name === action.payload.name && el.lastName === action.payload.lastName
      )
      if (!exist) {
        return {
          ...state,
          home: [...state.home, action.payload],
        }
      } else {
        return {
          ...state,
          home: [
            ...state.home.map((benefit: any) => {
              if (
                benefit.name === action.payload.name &&
                benefit.lastName === action.payload.lastName
              ) {
                benefit = { ...benefit, ...action.payload }
              }
              return { ...benefit }
            }),
          ],
        }
      }
    case personTypes.PERSON_REMOVE_FAMILY:
      return {
        ...state,
        home: [
          ...state.home.filter((benefit: any) => {
            return (
              benefit.name !== action.payload.name ||
              benefit.lastName !== action.payload.lastName ||
              benefit.birthDate !== action.payload.birthDate
            )
          }),
        ],
      }
      case personTypes.PEOPLE_REMOVE_ALL_FAMILY:
        return {
          ...state,
          home: [],
          person: {
            ...state.person,
            family: [],
          },
        }
    case personTypes.PERSON_INFO_SUCCES:
      return {
        ...state,
        person: action.payload,
      }
    case personTypes.PERSON_ADD_TO_FAMILY:
      const index = state.person.family.findIndex((el: any) => el.id === action.payload.id)
      if (index === -1) {
        if (state.person.family.length > 0) {
          const headHome = state.person.family[0]
          const edadHeadHome = calcularEdad(headHome.birthDate)
          if (edadHeadHome < 18) {
            const idxNewLeadHome = state.person.family.findIndex(
              (i) => calcularEdad(i.birthDate) >= 18
            )
            if (idxNewLeadHome >= 0) {
              return {
                ...state,
                person: {
                  ...state.person,
                  family: [
                    state.person.family[idxNewLeadHome],
                    ...state.person.family.filter((i, idx) => idx !== idxNewLeadHome),
                    action.payload,
                  ],
                },
              }
            } else {
              return {
                ...state,
                person: {
                  ...state.person,
                  family: [action.payload, ...state.person.family],
                },
              }
            }
          } else {
            return {
              ...state,
              person: {
                ...state.person,
                family: [...state.person.family, action.payload],
              },
            }
          }
        } else {
          return {
            ...state,
            person: {
              ...state.person,
              family: [...state.person.family, action.payload],
            },
          }
        }
      }
      return { ...state }
    case personTypes.REMOVE_PERSON_OF_LIST:
      return {
        ...state,
        person: {
          ...state.person,
          family: [...state.person.family.filter((p) => p.id !== action.payload.id)],
        },
      }
      case personTypes.REMOVE_ALL_PEOPLE_OF_LIST:
        return {
          ...state,
          person: {
            ...state.person,
            family: [],
          },
        }
    case personTypes.PERSON_FINISH_SERVICE:
      return {
        ...state,
        person: { ...state.person, family: [] },
      }
    case personTypes.CLEAR_PERSON_FAMILY:
      return {
        ...state,
        person: { ...state.person, family: [] },
      }
    case personTypes.PERSON_FINISH_CREATE_FAMILY:
      return {
        ...state,
        home: [],
      }
    case personTypes.SET_BENEFICIARY_INFO:
      return {
        ...state,
        detail: action.payload,
      }
    case personTypes.CLEAN_BENEFICIARY_INFO:
      return {
        ...state,
        detail: {},
      }
    default:
      return { ...state }
  }
}

export default Persons
