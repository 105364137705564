export const accommodationTypes = {
  ACCOMMODATION_INFO: "@@accommodation/@@ACCOMMODATION_INFO",
  ACCOMMODATION_CLEAR_INFO: "@@accommodation/@@ACCOMMODATION_CLEAR_INFO",

  ACCOMMODATION_DETAIL_MIGRANT_INFO: "@@accommodation/@@ACCOMMODATION_DETAIL_MIGRANT_INFO",
  ACCOMMODATION_DETAIL_MIGRANT_INFO_CLEAR:
    "@@accommodation/@@ACCOMMODATION_DETAIL_MIGRANT_INFO_CLEAR",

  ACCOMMODATION_TYPE: "@@accommodation/@@ACCOMMODATION_TYPE",
  SET_ACCOMMODATION_TYPE: "@@accommodation/@@SET_ACCOMMODATION_TYPE",

  ACCOMMODATION_INGRESS_FAMILY: "@@accommodation/@@ACCOMMODATION_INGRESS_FAMILY",

  ACCOMMODATION_ADD_INGRESS_PERSON: "@@accommodation/@@ACCOMMODATION_ADD_INGRESS_PERSON",
  ACCOMMODATION_REMOVE_INGRESS_PERSON: "@@accommodation/@@ACCOMMODATION_REMOVE_INGRESS_PERSON",
  ACCOMMODATION_CLEAR_INGRESS: "@@accommodation/@@ACCOMMODATION_CLEAR_INGRESS",

  ACCOMMODATION_ADD_ADDITIONAL_PERSON: "@@accommodation/@@ACCOMMODATION_ADD_ADDITIONAL_PERSON",
  ACCOMMODATION_REMOVE_ADDITIONAL_PERSON:
    "@@accommodation/@@ACCOMMODATION_REMOVE_ADDITIONAL_PERSON",
  ACCOMMODATION_CLEAR_ADDITIONAL_INGRESS:
    "@@accommodation/@@ACCOMMODATION_CLEAR_ADDITIONAL_INGRESS",

  ACCOMMODATION_EXIT_FAMILY: "@@accommodation/@@ACCOMMODATION_EXIT_FAMILY",

  ACCOMMODATION_SELECTION_EXIT_PEOPLE: "@@accommodation/@@ACCOMMODATION_SELECTION_EXIT_PEOPLE",
  ACCOMMODATION_CLEAR_SELECTION_EXIT_PEOPLE:
    "@@accommodation/@@ACCOMMODATION_CLEAR_SELECTION_EXIT_PEOPLE",

  START_GET_ACCOMMODATION_USER: "@@accommodation/@@START_GET_ACCOMMODATION_USER",
  SET_ACCOMMODATION_USER: "@@accommodation/@@SET_ACCOMMODATION_USER",

  START_GET_MIGRANT_ACCOMMODATION_ADITIONAL_SERVICES_USER: "@@accommodation/@@START_GET_ACCOMMODATION__ADITIONAL_SERVICES_USER",
  SET_ACCOMMODATION_ADITIONAL_SERVICES_USER: "@@accommodation/@@SET_ACCOMMODATION_ADITIONAL_SERVICES_USER",

  GET_MIGRANT_ACCOMMODATION_ADITIONAL_SERVICES_DATA: "@@accommodation/@@GET_MIGRANT_ADITIONAL_SERVICES_DATA",
}
