import { APICore } from "../api/apiCore"

//Variables de configuracion
const api = new APICore()
const baseApiurl = process.env.REACT_APP_TRANSPORT_URL
const baseApiurlPersons = process.env.REACT_APP_PERSONS_URL
const baseApiUsers = process.env.REACT_APP_USER_URL

const getListRequestTransport = (params = null) => {
  const baseUrl = baseApiurl + "/registries"
  return params ? api.get(`${baseUrl}`, params) : api.get(`${baseUrl}`)
}

const getListPFTransport = () => {
  const baseUrl = baseApiurl + "/focalpoints"
  return api.get(`${baseUrl}`)
}

const updateStatusRequest = (id) => {
  const baseUrl = baseApiurl + `/requests/${id}`
  return api.get(`${baseUrl}`)
}

const createRequestTransport = (data) => {
  const baseUrl = baseApiurl + `/registries`
  return api.create(`${baseUrl}`, data)
}

const updateRequestTransport = (data, id) => {
  const baseUrl = baseApiurl + `/registries/${id}`
  return api.update(`${baseUrl}`, data)
}

const createFocalPoint = () => {
  const baseUrl = baseApiurl + `/focalpoints`
  return api.create(`${baseUrl}`)
}

const getFocalPoints = () => {
  const baseUrl = baseApiUsers + `/entities?type=PF_TRANSPORT`
  return api.get(`${baseUrl}`)
}

const updateStatusRequestTransport = (id, data) => {
  const baseUrl = baseApiurl + `/registries/${id}`
  return api.updatePatch(`${baseUrl}`, data)
}

const getLastRegisterProByMigranID = (id) => {
  const baseUrl = baseApiurlPersons + `/pro/registries/last-register-by-migrant-id?id=${id}`
  return api.get(`${baseUrl}`)
}

const createReferenciatorTransport = (data) => {
  const baseUrl = `${baseApiurl}/referenciator`
  return api.create(baseUrl, data)
}

const getReferenciatorTransportByEmail = (email) => {
  const baseUrl = baseApiurl + `/referenciator/by-email?email=${email}`
  return api.get(baseUrl)
}

const updateReferenciatorTransport = (id, data) => {
  const baseUrl = `${baseApiurl}/referenciator/${id}`
  return api.create(baseUrl, data)
}

const getTransportKpi = (filters, params) => {
  const baseUrl = baseApiurl + `/kpi/${filters}`
  return api.get(baseUrl, params)
}

export {
  getListRequestTransport,
  getListPFTransport,
  updateStatusRequest,
  createRequestTransport,
  getFocalPoints,
  updateRequestTransport,
  createFocalPoint,
  updateStatusRequestTransport,
  getLastRegisterProByMigranID,
  createReferenciatorTransport,
  getReferenciatorTransportByEmail,
  updateReferenciatorTransport,
  getTransportKpi,
}
