export const personTypes = {
    PERSON_INFO: '@@person/@@PERSON_INFO',
    PERSON_INFO_SUCCES: '@@person/@@PERSON_INFO_SUCCES',
    PERSON_INFO_NOT_FOUND_REGISTER: '@@person/@@PERSON_INFO_NOT_FOUND_REGISTER',
    PERSON_INFO_NOT_FOUND_REGISTER_CLEAR_DATA: '@@person/@@PERSON_INFO_NOT_FOUND_REGISTER_CLEAR_DATA',
    PERSON_ADD_TO_FAMILY: '@@person/@@PERSON_ADD_TO_FAMILY',
    REMOVE_PERSON_OF_LIST: '@@person/@@REMOVE_PERSON_OF_LIST',
    REMOVE_ALL_PEOPLE_OF_LIST: '@@person/@@REMOVE_ALL_PEOPLE_OF_LIST',
    PERSON_FAMILY_LIST: '@@person/@@PERSON_FAMILY_LIST',
    PERSON_FINISH_SERVICE: '@@person/@@PERSON_FINISH_SERVICE',
    PERSON_CREATE_FAMILY: '@@person/@@PERSON_CREATE_FAMILY',
    PERSON_REMOVE_FAMILY: '@@person/@@PERSON_REMOVE_FROM_FAMILY',
    PEOPLE_REMOVE_ALL_FAMILY: '@@person/@@PEOPLE_REMOVE_ALL_FAMILY',
    CLEAR_PERSON_FAMILY: '@@person/@@CLEAR_PERSON_FAMILY',
    PERSON_FINISH_CREATE_FAMILY: '@@person/@@PERSON_FINISH_CREATE_FAMILY',

    ADD_MEMBER: '@@person/@@ADD_MEMBER',
    REMOVE_MEMBER: '@@person/@@REMOVE_MEMBER',
    CLEAR_MEMBER_LIST: '@@person/@@CLEAR_MEMBER_LIST',

    SET_BENEFICIARY_INFO : '@@person/@@SET_BENEFICIARY_INFO',
    CLEAN_BENEFICIARY_INFO : '@@person/@@CLEAN_BENEFICIARY_INFO',

};
