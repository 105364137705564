import axios from "axios"
/* import MySwal from "../../components/SwetAlert"
const PUBLIC_REQUEST_KEY = "public-request" */
export const AUTH_TOKEN = "auth_token"
export const LAUNCH_EXIT_FLAG = null
export const EXPIRATION_TIME = "expiration_time"
/* const TOKEN_PAYLOAD_KEY = "Authorization"
const baseApiurlUsersAuth = process.env.REACT_APP_AUTH_USERS_URL */

const AUTH_SESSION_KEY = "oim-user"

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token
  else delete axios.defaults.headers.common["Authorization"]
}

const getUserFromSession = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY)
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null
}

/*
Check if token available in session
*/
const user = getUserFromSession()
if (user) {
  const { token } = user
  if (token) {
    setAuthorization(token)
  }
}

class APICore {
  /**
   * Fetches data from given url
   */
  get = (url, params, headers = null) => {
    let response
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : ""
      if (headers) {
        response = axios.get(`${url}?${queryString}`, headers)
      } else {
        response = axios.get(`${url}?${queryString}`)
      }
    } else if (headers) {
      response = axios.get(`${url}`, headers)
    } else {
      response = axios.get(`${url}`, params)
    }
    return response
  }

  getFile = (url, params) => {
    let response
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : ""
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" })
    } else {
      response = axios.get(`${url}`, { responseType: "blob" })
    }
    return response
  }

  getMultiple = (urls, params) => {
    const reqs = []
    let queryString = ""
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : ""
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`))
    }
    return axios.all(reqs)
  }

  createWithAuth = (url, data, headers) => {
    return axios.post(url, data, headers)
  }

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data)
  }

  /**
   * Updates patch data
   */
  updatePatch = (url, data) => {
    return axios.patch(url, data)
  }

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data)
  }

  /**
   * Deletes data
   */
  delete = (url) => {
    return axios.delete(url)
  }

  /**
   * post given data to url with file
   */
  createWithFile = (url, data) => {
    const formData = new FormData()
    for (const k in data) {
      formData.append(k, data[k])
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    }
    return axios.post(url, formData, config)
  }

  /**
   * post given data to url with file
   */
  updateWithFile = (url, data) => {
    const formData = new FormData()
    for (const k in data) {
      formData.append(k, data[k])
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    }
    return axios.patch(url, formData, config)
  }

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser()
    if (!user || (user && !user.token)) {
      return false
    }
    /*const decoded = jwtDecode(user.token, {header: true});
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      return false;
    } else {
      return true;
    }*/
  }

  setLoggedInUser = (session) => {
    if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session))
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY)
    }
  }

  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromSession()
  }

  setUserInSession = (modifiedUser) => {
    const userInfo = sessionStorage.getItem(AUTH_SESSION_KEY)
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo)
      this.setLoggedInUser({ token, ...user, ...modifiedUser })
    }
  }
}

export { APICore, setAuthorization }
