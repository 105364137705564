import { APICore } from "../api/apiCore"

const api = new APICore()

const baseUserUrl = process.env.REACT_APP_USER_URL

const getListUsers = (params = { limit: 5, page: 1 }) => {
  const baseUrl = `${baseUserUrl}/users`
  return api.get(baseUrl, params)
}

function getListEntites() {
  const baseUrl = `${baseUserUrl}/entities`
  return api.get(baseUrl)
}

const postRegisterUser = (data) => {
  const baseUrl = `${baseUserUrl}/users`
  return api.create(baseUrl, data)
}

const postRegisterEntitie = (data) => {
  const baseUrl = `${baseUserUrl}/entities`
  return api.create(baseUrl, data)
}

function getEntityById(id) {
  const baseUrl = `${baseUserUrl}/entities/${id}`
  return api.get(baseUrl)
}

const getEntitieByType = (type = "PF_TRANSPORT") => {
  const baseUrl = `${baseUserUrl}/entities?type=${type}`
  return api.get(`${baseUrl}`)
}

const updateStatusUser = (id, data) => {
  const baseUrl = `${baseUserUrl}/users/${id}/active`
  return api.updatePatch(`${baseUrl}`, data)
}

const getUserById = (id) => {
  const baseUrl = `${baseUserUrl}/users/${id}`
  return api.get(baseUrl)
}

const updateUserById = (id, data) => {
  const baseUrl = `${baseUserUrl}/users/${id}`
  return api.update(baseUrl, data)
}

const updatePasswordUser = (id, data) => {
  const baseUrl = `${baseUserUrl}/users/${id}/password`
  return api.updatePatch(baseUrl, data)
}

export {
  getListUsers,
  getListEntites,
  postRegisterUser,
  postRegisterEntitie,
  getEntityById,
  getEntitieByType,
  updateStatusUser,
  getUserById,
  updateUserById,
  updatePasswordUser,
}
