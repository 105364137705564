export const TransportTypes = {
  SET_TYPE_REGISTRIES_TRANSPORT: "@@transport/SET_TYPE_REGISTRIES_TRANSPORT",
  SET_LIST_TRANSPORT: "@@transport/SET_LIST_TRANSPORT",

  REMOVE_PERSON_OF_LIST_TRANSPORT: "@@transport/REMOVE_PERSON_OF_LIST_TRANSPORT",

  SET_IFO_REQUEST_TRANSPORT: "@@transport/SET_IFO_REQUEST_TRANSPORT",

  RESET_TRANSPORT_STATE: "@@transport/RESET_TRANSPORT_STATE",

  CLEAR_LIST_TRANSPORT_STATE: "@@transport/CLEAR_LIST_TRANSPORT_STATE",
}
