// @flow
import { UtilityTypes } from "./constants"
import { ServiceProDataSetType } from "./reducers"

type UtilityAction = { type: string; payload: any }

export enum TypeServicePro {
  beneficiaryTypes = "beneficiaryTypes",
  disabilities = "disabilities",
  homeRoles = "homeRoles",
  vulnerabilities = "vulnerabilities",
  etpvInformations = "etpvInformations",
  etpvOrientations = "etpvOrientations",
  informationServices = "informationServices",
  kitServicesGiven = "kitServicesGiven",
  kitServices = "kitServices",
  migrationSituations = "migrationSituations",
  migrationProfiles = "migrationProfiles",
  orientationServices = "orientationServices",
  pointAssistanceServices = "pointAssistanceServices",
  protectionAssistanceServices = "protectionAssistanceServices",
  referenceServices = "referenceServices",
  providerInstitution = "providerInstitution",
  providerMode = "providerMode",
  providerPointService = "providerPointService",
  communicationChanel = "communicationChanel",
}

export const starGetListGenders: () => UtilityAction = () => ({
  type: UtilityTypes.START_LIST_GENDERS,
  payload: null,
})

export const succesListGenders: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_GENDERS,
  payload: data,
})

export const starGetListGendersIdentity: () => UtilityAction = () => ({
  type: UtilityTypes.START_LIST_GENDERS_IDENTITIES,
  payload: null,
})

export const succesListGendersIdentity: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_GENDERS_IDENTITIES,
  payload: data,
})

export const starGetIdentityTypes: () => UtilityAction = () => ({
  type: UtilityTypes.START_IDENTITYTYPES,
  payload: null,
})

export const succesListIdentityTypes: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_IDENTITYTYPES,
  payload: data,
})

export const starGetNationalitys: () => UtilityAction = () => ({
  type: UtilityTypes.START_NATIONALITIES,
  payload: null,
})

export const succesListNationalitys: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_NATIONALITIES,
  payload: data,
})

export const starGetEthnicities: () => UtilityAction = () => ({
  type: UtilityTypes.START_ETHNICITIES,
  payload: null,
})

export const succesListEthnicities: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_ETHNICITIES,
  payload: data,
})

export const starGetCountries: () => UtilityAction = () => ({
  type: UtilityTypes.START_COUNTRIES,
  payload: null,
})

export const succesListContries: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_COUNTRIES,
  payload: data,
})

export const setActiveTab: (id: any) => UtilityAction = (id) => ({
  type: UtilityTypes.SET_ACTIVE_TAB,
  payload: id,
})

export const starGetCities: () => UtilityAction = () => ({
  type: UtilityTypes.START_CITIES,
  payload: null,
})

export const starGetCitiesByDepartment = (departmentCode: string) => ({
  type: UtilityTypes.START_CITIES_BY_DEPARTMENT,
  payload: departmentCode,
})

export const succesListCities: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_CITIES,
  payload: data,
})

export const starGetDepartments = (countryCode: string = "64") => ({
  type: UtilityTypes.START_DEPARTMENTS,
  payload: countryCode,
})

export const succesListDepartments: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_DEPARTMENTS,
  payload: data,
})

export const starGetInstitutes: () => UtilityAction = () => ({
  type: UtilityTypes.START_INSTITUTES,
  payload: null,
})

export const succesListInstitutes: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_INSTITUTES,
  payload: data,
})

export const starGetServicesPROList = (typeService: TypeServicePro) => ({
  type: UtilityTypes.START_GET_SERVICE_PRO_DATA_SETS,
  payload: typeService,
})

export const succesGetServicesPROList = (
  typeService: TypeServicePro,
  data: ServiceProDataSetType[]
) => ({
  type: UtilityTypes.SUCCESS_GET_SERVICE_PRO_DATA_SETS,
  payload: { typeService, data },
})

export const starGetReasonsTransport: () => UtilityAction = () => ({
  type: UtilityTypes.START_REASONS_TRANSPORT,
  payload: null,
})

export const succesListReasonsTransport: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_REASONS_TRANSPORT,
  payload: data,
})

export const starGetTypeTransport: () => UtilityAction = () => ({
  type: UtilityTypes.START_TYPE_TRANSPORT,
  payload: null,
})

export const succesListTypeTransport: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_TYPE_TRANSPORT,
  payload: data,
})

export const starGetEntitiesType: (payload: string) => UtilityAction = (payload: string) => ({
  type: UtilityTypes.START_GET_ENTITIES_BY_TYPE,
  payload,
})

export const successGetEntitiesType: (data: any[], key: string) => UtilityAction = (data, key) => ({
  type: UtilityTypes.SUCCES_GET_ENTITIES_BY_TYPE,
  payload: { data, key },
})

export const starGetListRelationShips: () => UtilityAction = () => ({
  type: UtilityTypes.START_RELATIONSHIPS,
  payload: null,
})

export const successGetListRelationShips: (list: any[]) => UtilityAction = (list) => ({
  type: UtilityTypes.SUCCESS_LIST_RELATIONSHIPS,
  payload: list,
})

export const loadingGetListKits = () => ({
  type: UtilityTypes.LOADING_GET_KITS_LIST,
  payload: null,
})

export const successGetListKits = (payload: any) => ({
  type: UtilityTypes.SUCCESS_GET_KITS_LIST,
  payload,
})

export const starGetEntities: () => UtilityAction = () => ({
  type: UtilityTypes.START_ENTITIES,
  payload: null,
})

export const successGetEntities: (data: any) => UtilityAction = (data) => ({
  type: UtilityTypes.SUCCES_LIST_ENTITIES,
  payload: data,
})
