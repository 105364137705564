import { Fab } from "@material-ui/core"
import React, { useState } from "react"
//import { Dropdown } from "react-bootstrap";
import { IoMdPeople } from "react-icons/io"
import NotificationDropdown from "../NotificationDropdown"
import { StyledFloatIconWrapper } from "./FloatIcon.styles"
import { useGenerlsForms } from "../../hooks/useGenerlsForms"

export const FloatIconPersons = () => {
  const [show, setShow] = useState(false)

  const handleOpen = () => {
    setShow(!show)
  }

  const { validateData, validateActionForm } = useGenerlsForms()

  return (
    <StyledFloatIconWrapper>
      {validateActionForm() > 0 && (
        <div>
          <Fab
            style={{ color: "blue", boxShadow: "none", backgroundColor: "#5B92E5" }}
            disableRipple
            onClick={handleOpen}
          >
            <IoMdPeople size="2rem" color="white" />
          </Fab>
          <NotificationDropdown
            data={validateData()}
            setShow={setShow}
            dropdownOpen={show}
            notifications={[]}
          />
        </div>
      )}
    </StyledFloatIconWrapper>
  )
}
