import { Asignation, CreateDeliveryAsignation, Dealer } from '../../pages/NFI/models/nfi.model';
import { NFIActions } from "./constants";
export interface NFIState {
    loading: boolean,
    asignations: {
        list: Asignation[];
        detailAsignation: Asignation | Partial<Asignation> | null,
        saveAsignation: any,
        asignationToDelivery: Asignation,
        kitList: {id: number, name: string, active: boolean}[]
    };
    dealers: {
        preRegisterDealer: Dealer | null,
        list: Dealer[],
        from: string|null,
        entities: {
            id: number,
            name: string,
        }[]
    },
    registerDeliveries: {
        detailRegisterDelivery: CreateDeliveryAsignation | null,
        detailFormRegisterDelivery: any
    },
}

const initState: NFIState = {
    loading: false,
    asignations: {
        list: [],
        detailAsignation: null,
        saveAsignation: null,
        asignationToDelivery: null,
        kitList: [],
    },
    dealers: {
        preRegisterDealer: null,
        list: [],
        entities: [],
        from: null,
    },
    registerDeliveries: {
        detailRegisterDelivery: null,
        detailFormRegisterDelivery: null,
    },
}

function NfiReducer(state = initState, action: any): NFIState {
    switch (action.type) {
        case NFIActions.TOOGLE_LOADING_STATUS:
            return {
                ...state,
                loading: action.payload === null ? !state.loading : action.payload,
            };
        case NFIActions.GET_ASIGNATIONS_LIST:
            return {
                ...state,
                asignations: {
                    ...state.asignations,
                    list: action.payload,
                },
            };
        case NFIActions.SUCCESS_CREATE_ASIGNATION:
            return {
                ...state,
                asignations: {
                    ...state.asignations,
                    list: [...state.asignations.list, action.payload],
                },
            };
        case NFIActions.SUCCESS_ADD_NEW_KIT_ASIGNATION:
            return {
                ...state,
                loading: false,
                asignations: {
                    ...state.asignations,
                    detailAsignation: {
                        ...state.asignations.detailAsignation,
                        nfiAssignments: action.payload,
                    },
                },
            };
        case NFIActions.SUCCESS_CHANGE_STATUS_ASIGNATION:
            return {
                ...state,
                asignations: {
                    ...state.asignations,
                    detailAsignation: action.payload,
                },
            };
        case NFIActions.GET_ENTITIES_LIST:
            return {
                ...state,
                dealers: {
                    ...state.dealers,
                    entities: action.payload,
                },
            };
        case NFIActions.SET_DETAIL_ASIGNATIONS:
            return {
                ...state,
                asignations: {
                    ...state.asignations,
                    detailAsignation: action.payload,
                },
            };
        case NFIActions.SET_ASIGNATIONS_DELIVERY:
            return {
                ...state,
                asignations: {
                    ...state.asignations,
                    asignationToDelivery: action.payload,
                },
            };
        case NFIActions.SAVE_PRE_ASIGNATIONS:
            return {
                ...state,
                asignations: {
                    ...state.asignations,
                    saveAsignation: action.payload,
                },
            };
        case NFIActions.CREATE_DEALER_FROM:
            return {
                ...state,
                dealers: {
                    ...state.dealers,
                    from: action.payload,
                },
            };
        case NFIActions.CLEAR_PRE_REGISTER_DEALER:
            return {
                ...state,
                dealers: {
                    ...state.dealers,
                    preRegisterDealer: null,
                },
            };
        case NFIActions.CREATE_PRE_REGISTER_DEALER:
            return {
                ...state,
                dealers: {
                    ...state.dealers,
                    preRegisterDealer: action.payload,
                },
            };
        case NFIActions.SUCCESS_CREATE_DEALER:
            return {
                ...state,
                dealers: {
                    ...state.dealers,
                    list: [...state.dealers.list, action.payload],
                },
            };
        case NFIActions.SUCCESS_GET_LIST_DEALER:
            return {
                ...state,
                dealers: {
                    ...state.dealers,
                    list: action.payload,
                },
            };
        case NFIActions.SET_DETAIL_REGISTER_DELIVERY:
            return {
                ...state,
                registerDeliveries: {
                    ...state.registerDeliveries,
                    detailRegisterDelivery: action.payload,
                },
            }
        case NFIActions.SET_DETAIL_FORM_REGISTER_DELIVERY:
            return {
                ...state,
                registerDeliveries: {
                    ...state.registerDeliveries,
                    detailFormRegisterDelivery: action.payload,
                },
            }
        default:
            return state;
    }
}

export default NfiReducer