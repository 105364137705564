// @flow
import { all } from "redux-saga/effects"

import authSaga from "./auth/saga"
import layoutSaga from "./layout/saga"
import utilitySaga from "./utility/saga"
import personSaga from "./persons/saga"
import accommodationSaga from "./Accommodation/saga"
import NfiSaga from "./nfi/sagas"

export default function* rootSaga() {
  yield all([authSaga(), layoutSaga(), utilitySaga(), personSaga(), accommodationSaga(), NfiSaga()])
}
