import React from "react"
import imageLogo from "../../assets/images/logo.svg"
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: "",
    }
  }

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() }
  }

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_ENV !== "development") {
      setTimeout(function () {
        window.location.replace("/")
      }, 5000)
    }

    this.logErrorToServices(error.toString(), info.componentStack)
  }

  // A fake logging service 😬
  logErrorToServices = console.log

  render() {
    if (this.state.errorMessage) {
      return (
        <div className="error-boundary">
          <img src={imageLogo} alt="Logo app" width="1rem" height="1rem" />
          <div className="error-boundary__text">{`Oops, lo sentimos algo salió mal`}</div>
          <div>${this.state.errorMessage}</div>
        </div>
      )
    }
    return this.props.children
  }
}
