import { all, takeEvery } from "@redux-saga/core/effects";
import { fork, call, put } from 'redux-saga/effects';
import { createDealer, getAllEntities, getAllDealers, createAsignation, updateKitsAsignation, ChangeStatusAsignation, createDeliveryAsignation } from "../../helpers/NFI";
import { Dealer, Asignation, CreateKitsAsignation, StatusAsignations } from '../../pages/NFI/models/nfi.model';
import { createUser } from "../auth/saga";
import { successCreateDealer, clearAssignPreRegisterDealer, getEntities, successGetAllDealers, successCreateAsignation, savePartialAsignations, successAddNewKitAsignation, successChangeStatusAsignation, setAsignationsDelivery, toggleNfiLoading } from './actions';
import { NFIActions } from "./constants";
import { User } from '../../pages/account/models/user.model';
import { History } from 'history';
import { addInformationSuccess } from '../information/actions';
import { clearPersonFamily } from '../persons/actions';

function* watcherLoadingCreateDealer() {
    yield takeEvery(NFIActions.LOADING_CREATE_DEALER, function* ({type, payload}: {
        type: string,
        payload: {
            dealerInfo: Partial<Dealer>,
            userInfo: User,
            from: string | null,
            history: History<any>,
        }
    }) {
        try {
            const { data } = yield call(createDealer, payload.dealerInfo);
            if (data) {
                const respCreateUser = yield call(createUser, {payload: payload.userInfo});
                if (respCreateUser) {
                    yield put(successCreateDealer(data))
                    if (payload.from) {
                        const location = {
                            pathname: payload.from,
                            state: { dealer: data.id.toString() },
                        }
                        payload.history.push(location)
                    }
                    else {
                        payload.history.push("/dashboard")
                    }
                }
            }
        } catch (error) {
            console.error("watcherLoadingCreateDealer error: ",{error})
        }
    })
}

function* watcherSuccessCreateDealer() {
    yield takeEvery(NFIActions.SUCCESS_CREATE_DEALER, function* () {
        yield put(clearAssignPreRegisterDealer())
    })
}

function* watcherLoadingCreateAsignations() {
    yield takeEvery(NFIActions.LOADING_CREATE_ASIGNATION, function* ({ payload }: {
        type: string,
        payload: {asignation: Partial<Asignation>, history: History<any>, formData: {totalKits: any[], dealer: Partial<Dealer>}},
    }) {
        const { data } = yield call(createAsignation, payload.asignation);
        let listKits = "";
        let idx = 1
        for (const data of payload.formData.totalKits) {
            listKits += `<li><strong>Kit ${idx}:</strong> ${data.label} x ${data.quanty}</li>`;
            idx++;
        }
        listKits += `<li><strong>NFI Dealer:</strong> ${payload.formData.dealer.id} - ${payload.formData.dealer.name}</li>`
        yield put(successCreateAsignation(data))
        yield put(savePartialAsignations(null))
        yield put(addInformationSuccess({
            breadCrumbItems: [
              {
                label: "Listado asignaciones",
                path: "/nfi/asignations",
              },
              {
                label: "Creación de asignación",
                path: `/nfi/create/asignations`,
              },
              {
                label: " Resumen de asignación creada",
                path: ``,
                active: true,
              },
            ],
            description: `
                <h2 className="text-blue-500 text-center mt-4">Asignación NFI creada exitosamente.</h2>
                <h4 className="font-bold text-center mt-4">Resumen de la asignación:</h4>
                <ul style="font-weight:normal;font-size: 1.2rem;list-style-type:none;">
                    ${listKits}
                </ul>
            `,
            title: "Asignaciones",
        }))
        payload.history.push("/information/success")
    })
}

function* watcherLoadingCreateDeliveryAsignations() {
    yield takeEvery(NFIActions.LOADING_CREATE_DELIVERY_ASIGNATION, function* ({ payload }: {
        type: string,
        payload: {asignation: Partial<Asignation>, history: History<any>, formData: any},
    }) {
        const data = yield call(createDeliveryAsignation, payload.asignation);
        let listKits = "";
        for (const dataForm of payload.formData.migrants) {
            for (const kit of dataForm.quantyPerKit) {
                listKits += `<li><strong>${dataForm.migrantName}</strong>: ${kit.kitName} x ${kit.quanty}</li>`;
            }
        }
        if (data) {
            yield put(addInformationSuccess({
                breadCrumbItems: [
                    {
                        label: "RegistroNFI",
                        path: "/nfi/service/create",
                    },
                    {
                        label: "Resumen de los servicios solicitados",
                        path: ``,
                        active: true,
                    },
                ],
                description: `
                    <h2 className="text-blue-500 text-center mt-4">Registro de Servicios NFI exitoso.</h2>
                    <h4 className="font-bold text-center mt-4">Resumen de los servicios solicitados:</h4>
                    <ul style="font-weight:normal;font-size: 1.2rem;list-style-type:none;">
                        ${listKits}
                    </ul>
                `,
                title: "Asignaciones",
            }))
            payload.history.push("/information/success")
            yield put(clearPersonFamily())
            yield put(setAsignationsDelivery(null))
        }
    })
}

function* watcherLoadingChangeStatusAsignations() {
    yield takeEvery(NFIActions.LOADING_CHANGE_STATUS_ASIGNATION, function* ({payload}: {type: string, payload: {idAsignation: number, status: StatusAsignations}}) {
        const params = {
            status: payload.status,
        }
        const { data } = yield call(ChangeStatusAsignation, payload.idAsignation, params);
        yield put(successChangeStatusAsignation(data))
        yield put(toggleNfiLoading(false))
    })
}

// function* watcherLoadingAsignations() {
//     yield takeEvery(NFIActions.LOADING_GET_ASIGNATIONS_LIST, function* ({payload}: {type: string, payload: {status: string, dealerId?: number, focalPointId?: number, limit: number, page: number}}) {
//         const { data } = yield call(getNFIListAsignations, {...payload});
//         yield put(getAsignations(data))
//     })
// }

function* watcherLoadingGetDealers() {
    yield takeEvery(NFIActions.LOADING_GET_LIST_DEALERS, function* () {
        const { data } = yield call(getAllDealers);
        yield put(successGetAllDealers(data))
    })
}

function* watcherLoadingAddNewKit() {
    yield takeEvery(NFIActions.LOADING_ADD_NEW_KIT_ASIGNATION, function* ({payload}: {type: string, payload: {
        idAsignation: number, kit: CreateKitsAsignation[]
    }}) {
        const { data } = yield call(updateKitsAsignation, payload.idAsignation, payload.kit);
        yield put(successAddNewKitAsignation(data.nfiAssignments))
        yield put(toggleNfiLoading(false))
    })
}

function* watcherLoadingEntities() {
    yield takeEvery(NFIActions.LOADING_GET_ENTITIES_LIST, function* () {
        const { data } = yield call(getAllEntities);
        yield put(getEntities([...data, {id: -1, name: "Otro"}]))
    })
}

function* NFISaga() {
    yield all([
        fork(watcherLoadingCreateDeliveryAsignations),
        fork(watcherLoadingChangeStatusAsignations),
        fork(watcherLoadingAddNewKit),
        fork(watcherLoadingCreateAsignations),
        fork(watcherLoadingGetDealers),
        fork(watcherLoadingEntities),
        fork(watcherLoadingCreateDealer),
        fork(watcherSuccessCreateDealer),
        // fork(watcherLoadingAsignations),
    ]);
}

export default NFISaga;