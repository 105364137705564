import { calcularEdad } from "../../utils/utils";
import { TransportTypes } from "./constants"

const initState = {
  request: {},
  people: [],
  type: 0,
}

function transportReducer(state = initState, action: any) {
  switch (action.type) {
    case TransportTypes.SET_LIST_TRANSPORT:
      const index = state.people.findIndex((el) => el.id === action.payload.id)
      if (index === -1) {
        if (state.people.length > 0) {
          const headHome = state.people[0]
          const edadHeadHome = calcularEdad(headHome.birthDate);
          if (edadHeadHome < 18) {
            const idxNewLeadHome = state.people.findIndex(i => calcularEdad(i.birthDate) >= 18);
            if (idxNewLeadHome >= 0) {
              return {
                ...state,
                people: [state.people[idxNewLeadHome], ...state.people.filter((i, idx) => idx !== idxNewLeadHome), action.payload],
              }
            } else {
              return {
                ...state,
                people: [action.payload, ...state.people],
              }
            }
          } else {
            return {
              ...state,
              people: [...state.people, action.payload],
            }
          }
        } else {
          return {
            ...state,
            people: [...state.people, action.payload],
          }
        }
      }
      return { ...state }
    case TransportTypes.REMOVE_PERSON_OF_LIST_TRANSPORT:
      const removeIndex: any = state.people.map((item) => item.id === action.payload.id)
      if (removeIndex && state.people.splice(removeIndex, 1)) {
        return {
          ...state,
          people: [...state.people],
        }
      } else {
        return { ...state }
      }
    case TransportTypes.SET_IFO_REQUEST_TRANSPORT:
      return {
        ...state,
        request: action.payload,
      }
    case TransportTypes.SET_TYPE_REGISTRIES_TRANSPORT:
      return {
        ...state,
        type: action.payload,
      }
    case TransportTypes.CLEAR_LIST_TRANSPORT_STATE:
      return {
        ...state,
        people: [],
      }
    case TransportTypes.RESET_TRANSPORT_STATE:
      return {
        request: {},
        people: [],
        type: 0,
      }
    default:
      return state
  }
}

export default transportReducer
