// @flow
import { UtilityTypes } from "./constants"

import { Genders } from "../../interfaces/Gender"
import { IdentityTypes } from "../../interfaces/IdentityTypes"
import { Ethnicities } from "../../interfaces/Ethnicities"
import { Nationality } from "../../interfaces/Nationality"
import dayjs from "dayjs"

type UtilitiesAction = { type: string; payload?: any | null }

export interface ServiceProDataSetType {
  id: number
  name: string
  active?: number[]
  checked?: boolean
  disabled?: boolean
}

export interface UtilityState {
  listGenders?: Array<Genders>
  listGendersIdentity?: Array<Genders>
  listIdentityTypes?: Array<IdentityTypes>
  listEthnicities?: Array<Ethnicities>
  listNationality?: Array<Nationality>
  listCountries?: Array<any>
  listCities?: Array<any>
  listDepartments?: Array<any>
  listInstitutes?: Array<any>
  listReasonsTransport?: Array<any>
  listTypeTransport?: Array<any>
  listEntitiesType?: Array<{ id: number; name: string }>
  listEntities?: Array<any>
  listRelationShips?: Array<any>
  activeTab?: number
  serviceProDataSets: {
    beneficiaryTypes: ServiceProDataSetType[]
    disabilities: ServiceProDataSetType[]
    homeRoles: ServiceProDataSetType[]
    vulnerabilities: ServiceProDataSetType[]
    etpvInformations: ServiceProDataSetType[]
    etpvOrientations: ServiceProDataSetType[]
    informationServices: ServiceProDataSetType[]
    kitServicesGiven: ServiceProDataSetType[]
    kitServices: ServiceProDataSetType[]
    migrationSituations: ServiceProDataSetType[]
    migrationProfiles: ServiceProDataSetType[]
    orientationServices: ServiceProDataSetType[]
    pointAssistanceServices: ServiceProDataSetType[]
    protectionAssistanceServices: ServiceProDataSetType[]
    referenceServices: ServiceProDataSetType[]
  }
  lastUpdated: Date
}

const INIT_STATE: UtilityState = {
  listGenders: [],
  listGendersIdentity: [],
  listIdentityTypes: [],
  listEthnicities: [],
  listNationality: [],
  listCountries: [],
  listCities: [],
  listDepartments: [],
  listInstitutes: [],
  listEntitiesType: [],
  listEntities:[],
  serviceProDataSets: {
    beneficiaryTypes: [],
    disabilities: [],
    homeRoles: [],
    vulnerabilities: [],
    etpvInformations: [],
    etpvOrientations: [],
    informationServices: [],
    kitServicesGiven: [],
    kitServices: [],
    migrationSituations: [],
    migrationProfiles: [],
    orientationServices: [],
    pointAssistanceServices: [],
    protectionAssistanceServices: [],
    referenceServices: [],
  },
  listReasonsTransport: [],
  listTypeTransport: [],
  activeTab: 0,
  lastUpdated: dayjs(new Date()).subtract(5, "day").toDate(),
}

const Utilities = (state: UtilityState = INIT_STATE, action: UtilitiesAction): any => {
  switch (action.type) {
    case UtilityTypes.SUCCES_LIST_GENDERS:
      return {
        ...state,
        listGenders: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_GENDERS_IDENTITIES:
      return {
        ...state,
        listGendersIdentity: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_IDENTITYTYPES:
      return {
        ...state,
        listIdentityTypes: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_ETHNICITIES:
      return {
        ...state,
        listEthnicities: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_NATIONALITIES:
      return {
        ...state,
        listNationality: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_COUNTRIES:
      return {
        ...state,
        listCountries: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_CITIES:
      return {
        ...state,
        listCities: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_DEPARTMENTS:
      return {
        ...state,
        listDepartments: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_INSTITUTES:
      return {
        ...state,
        listInstitutes: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_REASONS_TRANSPORT:
      return {
        ...state,
        listReasonsTransport: action.payload,
      }
    case UtilityTypes.SUCCES_LIST_TYPE_TRANSPORT:
      return {
        ...state,
        listTypeTransport: action.payload,
      }
    case UtilityTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      }
    case UtilityTypes.SUCCESS_GET_SERVICE_PRO_DATA_SETS:
      return {
        ...state,
        serviceProDataSets: {
          ...state.serviceProDataSets,
          [action.payload.typeService]: action.payload.data,
        },
      }
    case UtilityTypes.SUCCES_GET_ENTITIES_BY_TYPE:
      return {
        ...state,
        listEntitiesType: {
          ...state.listEntitiesType,
          [action.payload.key]: action.payload.data,
        },
      }

    case UtilityTypes.SUCCES_LIST_ENTITIES:
      return {
        ...state,
        listEntities: action.payload,
      }
    case UtilityTypes.SUCCESS_LIST_RELATIONSHIPS:
      return {
        ...state,
        listRelationShips: action.payload,
      }
    case UtilityTypes.SUCCESS_GET_KITS_LIST:
      return {
        ...state,
        serviceProDataSets: {
          ...state.serviceProDataSets,
          kitServices: action.payload,
        },
      }
    default:
      return { ...state }
  }
}

export default Utilities
