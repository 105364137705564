// @flow
import { calcularEdad } from "../../utils/utils"
import { AuthActionTypes } from "../auth/constants"
import { accommodationTypes } from "./constants"

const INIT_STATE = {
  accomodation: {},
  detailMigrants: [],
  ingress: [],
  partialExit: {
    people: [],
  },
  additionalIngress: [],
  additionalServices: [],
  type: 0,
  accomodationUser: {},
}

const Accommodation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case accommodationTypes.ACCOMMODATION_INFO:
      return {
        ...state,
        accomodation: { ...action.payload },
      }
    case accommodationTypes.ACCOMMODATION_INGRESS_FAMILY:
      return {
        ...state,
        ingress: action.payload,
      }
    case accommodationTypes.ACCOMMODATION_ADD_INGRESS_PERSON:
      const index = state.ingress.findIndex((el) => el.id === action.payload.id)
      if (index === -1) {
        if (state.ingress.length > 0) {
          const headHome = state.ingress[0]
          const edadHeadHome = calcularEdad(headHome.birthDate)
          if (edadHeadHome < 18) {
            const idxNewLeadHome = state.ingress.findIndex((i) => calcularEdad(i.birthDate) >= 18)
            if (idxNewLeadHome >= 0) {
              return {
                ...state,
                ingress: [
                  state.ingress[idxNewLeadHome],
                  ...state.ingress.filter((i, idx) => idx !== idxNewLeadHome),
                  action.payload,
                ],
              }
            } else {
              return {
                ...state,
                ingress: [action.payload, ...state.ingress],
              }
            }
          } else {
            return {
              ...state,
              ingress: [...state.ingress, action.payload],
            }
          }
        } else {
          return {
            ...state,
            ingress: [...state.ingress, action.payload],
          }
        }
      }
      return { ...state }
    case accommodationTypes.ACCOMMODATION_REMOVE_INGRESS_PERSON:
      const removeIndex = state.ingress.map((item) => item.id === action.payload.id)
      if (removeIndex && state.ingress.splice(removeIndex, 1)) {
        return {
          ...state,
          ingress: [...state.ingress],
        }
      } else {
        return { ...state }
      }
    case accommodationTypes.ACCOMMODATION_ADD_ADDITIONAL_PERSON:
      const addIngres = state.additionalIngress.findIndex((el) => el.id === action.payload.id)
      if (addIngres === -1) {
        if (state.additionalIngress.length > 0) {
          const headHome = state.additionalIngress[0]
          const edadHeadHome = calcularEdad(headHome.birthDate)
          if (edadHeadHome < 18) {
            const idxNewLeadHome = state.additionalIngress.findIndex(
              (i) => calcularEdad(i.birthDate) >= 18
            )
            if (idxNewLeadHome >= 0) {
              return {
                ...state,
                additionalIngress: [
                  state.additionalIngress[idxNewLeadHome],
                  ...state.additionalIngress.filter((i, idx) => idx !== idxNewLeadHome),
                  action.payload,
                ],
              }
            } else {
              return {
                ...state,
                additionalIngress: [action.payload, ...state.additionalIngress],
              }
            }
          } else {
            return {
              ...state,
              additionalIngress: [...state.additionalIngress, action.payload],
            }
          }
        } else {
          return {
            ...state,
            additionalIngress: [...state.additionalIngress, action.payload],
          }
        }
      }
      return { ...state }
    case accommodationTypes.ACCOMMODATION_REMOVE_ADDITIONAL_PERSON:
      const removeIngress = state.additionalIngress.map((item) => item.id === action.payload.id)
      if (removeIngress && state.additionalIngress.splice(removeIngress, 1)) {
        return {
          ...state,
          additionalIngress: [...state.additionalIngress],
        }
      } else {
        return { ...state }
      }
    case accommodationTypes.SET_ACCOMMODATION_TYPE:
      return {
        ...state,
        type: action.payload,
      }
    case accommodationTypes.ACCOMMODATION_CLEAR_INGRESS:
      return {
        ...state,
        ingress: [],
      }
    case accommodationTypes.ACCOMMODATION_CLEAR_ADDITIONAL_INGRESS:
      return {
        ...state,
        additionalIngress: [],
      }
    case accommodationTypes.ACCOMMODATION_CLEAR_INFO:
      return {
        ...state,
        accomodation: {},
      }
    case accommodationTypes.ACCOMMODATION_SELECTION_EXIT_PEOPLE:
      return {
        ...state,
        partialExit: {
          ...state.partialExit,
          people: [...action.payload],
        },
      }
    case accommodationTypes.ACCOMMODATION_CLEAR_SELECTION_EXIT_PEOPLE:
      return {
        ...state,
        partialExit: {
          ...state.partialExit,
          people: [],
        },
      }
    case accommodationTypes.ACCOMMODATION_DETAIL_MIGRANT_INFO:
      return {
        ...state,
        detailMigrants: [...action.payload],
      }
    case accommodationTypes.ACCOMMODATION_DETAIL_MIGRANT_INFO_CLEAR:
      return {
        ...state,
        detailMigrants: [],
      }
    case accommodationTypes.SET_ACCOMMODATION_USER:
      return {
        ...state,
        accomodationUser: action.payload,
      }
    case AuthActionTypes.RESET:
      return INIT_STATE
    case accommodationTypes.SET_ACCOMMODATION_ADITIONAL_SERVICES_USER:
      return {
        ...state,
        additionalServices: action.payload,
      }
      case accommodationTypes.GET_MIGRANT_ACCOMMODATION_ADITIONAL_SERVICES_DATA:
        return {
          ...state,
          dataAdditionalServices: action.payload,
        }
    default:
      return { ...state }
  }
}

export default Accommodation
