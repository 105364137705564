import { trackPromise } from "react-promise-tracker"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import MySwal from "../../../components/SwetAlert"
import { createRequestTransport } from "../../../helpers/Transport"
import {
  actClearListPeopleTransport,
  actSetTransportRequest,
} from "../../../redux/transport/actions"

export const useTransport = () => {
  const { user } = useSelector((state) => state.Auth)
  const dispatch = useDispatch()
  const history = useHistory()
  const createBasicRegistry = (people, sourceType = "OTHER") => {
    const data = {
      migrantsQuantity: people.length,
      familyHeadId: people[0].id,
      familyHeadName: `${people[0].name} ${people[0].lastName}`,
      departmentCode: user?.departments[0],
      focalPointId: user?.focalPointId,
      entityId: user?.entityId,
      source: {
        type: sourceType,
        username: user.username,
      },
      migrants: people.map((p) => {
        return p.id
      }),
      status: "INCOMPLETE",
      username: user.username,
    }
    trackPromise(createRequestTransport(data))
      .then(({ data }) => {
        dispatch(
          actSetTransportRequest(data, () => {
            history.push(`/transport/request/${data.id}`)
            dispatch(actClearListPeopleTransport())
          })
        )
      })
      .catch((err) => {
        MySwal.fire({
          icon: "error",
          title: "Se presento un error registrando la solicitud de transporte.",
        })
      })
  }
  return {
    createBasicRegistry,
  }
}
