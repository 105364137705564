export const PROFILE_ADMIN = "Admin"

export const PROFILE_MONITOR = "Monitoreo"

export const PROFILES = [
  { value: 2, label: "Operario PRO" },
  { value: 3, label: "Operario PF PRO" },
  { value: 4, label: "Alojamiento" },
  { value: 5, label: "Operario PF Alojamiento" },
  { value: 6, label: "NFI Dealer" },
  { value: 7, label: "Operario PF NFI" },
  { value: 8, label: "Referenciador de transporte" },
  { value: 9, label: "Operario PF Transporte" },
  { value: 12, label: PROFILE_MONITOR },
  { value: 13, label: "Unidad Especializada" },
  { value: 14, label: "Enlace Territorial" },
]

export const PROFILES_FORM = {
  2: "PRO",
  4: "ALOJAMIENTO",
  5: "PF_ALOJAMIENTO",
  6: "NFI_DEALER",
  7: "PF_NFI",
  8: "REF_TRANSPORTE",
  9: "PF_TRANSPORTE",
  13:"UNIDAD ESPECIALIZADA",
  14:"ENLACE TERRITORIAL",
}

export const FOCALPOINT = {
  PRO: 1,
  PF_PRO: 2,
  PF_ALOJAMIENTO: 3,
  PF_NFI: 4,
  PF_TRANSPORTE: 5,
}

export const FOCALKEYPOINT = {
  PF_NFI: "PF_NFI",
  EXTERNAL: "EXTERNAL",
}

export const ROLES = {
  MANEJO_USUARIOS: 1,
  CREACION_KITS: 2,
  CREACION_ENTIDAD: 3,
  CREACIÓN_SOCIOS_IMPLEMENTADORES: 4,
  CONSULTA_PERSONA: 5,
  REGISTRO_PERSONA: 6,
  HISTORIAL_BENEFICIARIO: 7,
  REGISTRO_PRO: 8,
  SUPERVISION_PRO: 9,
  REGISTRO_ALOJAMIENTO: 10,
  SUPERVISION_ALOJAMIENTO: 11,
  REGISTRO_NFI: 12,
  SUPERVISION_NFI: 13,
  REGISTRO_TRANSPORTE: 14,
  SUPERVISION_TRANSPORTE: 15,
  CREACION_ENTIDADES: 16,
}

export const ENTITIE_TYPE = [
  { value: "PRO", label: "PRO" },
  { value: "NFI", label: "NFI" },
  { value: "Transporte", label: "TRANSPORTE" },
  { value: "Alojamiento", label: "ALOJAMIENTO" },
  { value: "PF_PRO", label: "PUNTO FOCAL PRO" },
  { value: "PF_NFI", label: "PUNTO FOCAL NFI" },
  { value: "PF_TRANSPORT", label: "PUNTO FOCAL TRANSPORTE" },
  { value: "PF_ACCOMMODATION", label: "PUNTO FOCAL ALOJAMIENTO" },
  { value: "UNIDAD_ESPECIALIZADA", label: "UNIDAD ESPECIALIZADA" },
  { value: "ENLACE_TERRITORIAL", label: "ENLACE TERRITORIAL" },
]

export const FOCAL_POINT_ENTITIE_TYPE = [
  { value: "PRO", label: "PRO" },
  { value: "PF_PRO", label: "PUNTO FOCAL PRO" },
  { value: "PF_NFI", label: "PUNTO FOCAL NFI" },
  { value: "PF_TRANSPORT", label: "PUNTO FOCAL TRANSPORTE" },
  { value: "PF_ACCOMMODATION", label: "PUNTO FOCAL ALOJAMIENTO" },
]
