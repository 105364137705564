export const UtilityTypes = {

    START_LIST_GENDERS: '@@utility/START_LIST_GENDERS',
    SUCCES_LIST_GENDERS: '@@utility/SUCCES_LIST_GENDERS',

    START_LIST_GENDERS_IDENTITIES: '@@utility/START_LIST_GENDERS_IDENTITIES',
    SUCCES_LIST_GENDERS_IDENTITIES: '@@utility/SUCCES_LIST_GENDERS_IDENTITIES',

    START_NATIONALITIES: '@@utility/START_NATIONALITIES',
    SUCCES_LIST_NATIONALITIES: '@@utility/SUCCES_LIST_NATIONALITIES',

    START_IDENTITYTYPES: '@@utility/START_IDENTITYTYPES',
    SUCCES_LIST_IDENTITYTYPES: '@@utility/SUCCES_LIST_IDENTITYTYPES',

    START_ETHNICITIES: '@@utility/START_ETHNICITIES',
    SUCCES_LIST_ETHNICITIES: '@@utility/SUCCES_LIST_ETHNICITIES ',

    START_COUNTRIES: '@@utility/START_COUNTRIES',
    SUCCES_LIST_COUNTRIES: '@@utility/SUCCES_LIST_COUNTRIES',

    START_CITIES: '@@utility/START_CITIES',
    START_CITIES_BY_DEPARTMENT: '@@utility/START_CITIES_BY_DEPARTMENT',
    SUCCES_LIST_CITIES: '@@utility/SUCCES_LIST_CITIES',

    START_DEPARTMENTS: '@@utility/START_DEPARTMENTS',
    SUCCES_LIST_DEPARTMENTS: '@@utility/SUCCES_LIST_DEPARTMENTS',

    START_INSTITUTES: '@@utility/START_INSTITUTES',
    SUCCES_LIST_INSTITUTES: '@@utility/SUCCES_LIST_INSTITUTES',

    START_GET_SERVICE_PRO_DATA_SETS: '@@utility/START_GET_SERVICE_PRO_DATA_SETS',
    SUCCESS_GET_SERVICE_PRO_DATA_SETS: '@@utility/SUCCESS_GET_SERVICE_PRO_DATA_SETS',
    START_REASONS_TRANSPORT: '@@utility/START_REASONS_TRANSPORT',
    SUCCES_LIST_REASONS_TRANSPORT: '@@utility/SUCCES_LIST_REASONS_TRANSPORT',


    START_TYPE_TRANSPORT: '@@utility/START_TYPE_TRANSPORT',
    SUCCES_LIST_TYPE_TRANSPORT: '@@utility/SUCCES_LIST_TYPE_TRANSPORT',

    START_GET_ENTITIES_BY_TYPE: '@@utility/START_GET_ENTITIES_BY_TYPE',
    SUCCES_GET_ENTITIES_BY_TYPE: '@@utility/SUCCES_GET_ENTITIES_BY_TYPE',

    SET_ACTIVE_TAB: '@@utility/SET_ACTIVE_TAB',

    LOADING_GET_KITS_LIST: "@@utility/LOADING_GET_KITS_LIST",
    SUCCESS_GET_KITS_LIST: "@@utility/SUCCESS_GET_KITS_LIST",

    START_ENTITIES : "@@utility/START_ENTITIES",
    SUCCES_LIST_ENTITIES: "@@utility/SUCCES_LIST_ENTITIES",

    START_RELATIONSHIPS : "@@utility/START_RELATIONSHIPS",
    SUCCESS_LIST_RELATIONSHIPS: "@@utility/SUCCESS_LIST_RELATIONSHIPS",
};
