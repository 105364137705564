import { InformationActionTypes } from './actions'
interface InformationState {
    success: {
        breadCrumbItems: {
            label: string,
            path: string,
            active?: boolean,
        }[],
        image: string,
        title: string,
        description: string,
        returnUrl: string;
    }
}

const initState: InformationState = {
    success: {
        breadCrumbItems: [],
        image: "",
        title: "",
        description: "",
        returnUrl: "",
    },
}

export function Information(state = initState, action:any) {
    switch (action.type) {
        case InformationActionTypes.SET_INFORMATION_DATA_SUCCESS:
            return {
                ...state,
                success: {...action.payload},
            }
        case InformationActionTypes.CLEAR_INFORMATION_DATA_SUCCESS:
            return {
                ...state,
                success: {...initState.success},
            }
        default:
            return state;
    }
}