import styled from "styled-components"

export const StyledFloatIconWrapper = styled.section.attrs({
  className: "StyledFloatIconWrapper",
})`
  position: fixed;
  top: 5rem;
  right: 0;
  @media (min-width: 768px) {
    right: 2rem;
  }
  .icon-toggle {
    display: none;
  }
  .dropdown-menu {
    right: 5rem;
    @media (max-width: 768px) {
      right: 1rem !important;
      max-width: 22rem;
    }
  }
  .simple-bar {
    max-height: 8rem;
  }
  @media print {
    display: none;
  }
`
