export enum InformationActionTypes {
    SET_INFORMATION_DATA_SUCCESS = "@@information/SET_INFORMATION_DATA_SUCCESS",
    CLEAR_INFORMATION_DATA_SUCCESS = "@@information/CLEAR_INFORMATION_DATA_SUCCESS"
}

interface InformationSuccess {
    breadCrumbItems: any[];
    title: string;
    description: string;
    extra?: {
        transport?: {
            hasTransport: boolean,
            multiple: boolean,
            data: any,
        }
    }
}

export const addInformationSuccess = (payload: InformationSuccess): {type: InformationActionTypes, payload?: any} => ({
    type: InformationActionTypes.SET_INFORMATION_DATA_SUCCESS,
    payload,
})


export const clearInformationSuccess = (payload: InformationSuccess): {type: InformationActionTypes, payload?: any} => ({
    type: InformationActionTypes.CLEAR_INFORMATION_DATA_SUCCESS,
    payload,
})