// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// actions
import { changeLayout, changeLayoutWidth, changeSidebarTheme, changeSidebarType } from '../redux/actions';

// constants
import * as layoutConstants from '../constants/layout';

type ThemeCustomizerState = {
    isHorizontalLayout?: boolean,
    isDetachedLayout?: boolean,
    isBoxed?: boolean,
    isSidebarScrollable?: boolean,
    isCondensed?: boolean,
    isLight?: boolean,
    isDark?: boolean,
};

const ThemeCustomizer = (state: ThemeCustomizerState): React$Element<any> => {
    const dispatch = useDispatch();

    const [disablelayoutWidth, setDisablelayoutWidth] = useState(false);
    const [disableSidebarTheme, setDisableSidebarTheme] = useState(false);
    const [disableSidebarType, setDisableSidebarType] = useState(false);

    const { layoutType, layoutWidth, leftSideBarType, leftSideBarTheme } = useSelector((state) => ({
        layoutType: state.Layout.layoutType,
        layoutWidth: state.Layout.layoutWidth,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        leftSideBarType: state.Layout.leftSideBarType,
        showRightSidebar: state.Layout.showRightSidebar,
    }));

    /**
     * change state based on props changes
     */
    const _loadStateFromProps = useCallback(() => {
        setDisablelayoutWidth(layoutType === 'detached' ? true : false);
        setDisableSidebarTheme(layoutType === 'detached' || layoutType === 'topnav' ? true : false);
        setDisableSidebarType(layoutType === 'topnav' ? true : false);
    }, [layoutType]);

    useEffect(() => {
        _loadStateFromProps();
    }, [_loadStateFromProps]);

    /**
     * On layout change
     */
    const changeLayoutType = (layout) => {
        dispatch(changeLayout(layout));
    };

    /**
     * Change the width mode
     */
    const changeWidthMode = (mode) => {
        dispatch(changeLayoutWidth(mode));
    };

    /**
     * Changes the theme
     */
    const changeTheme = (theme) => {
        dispatch(changeSidebarTheme(theme));
    };

    /**
     * Change the leftsiderbar type
     */
    const changeLeftSiderbarType = (type) => {
        dispatch(changeSidebarType(type));
    };

    /**
     * Reset everything
     */
    const reset = () => {
        changeLayoutType(layoutConstants.LAYOUT_VERTICAL);
        changeWidthMode(layoutConstants.LAYOUT_WIDTH_FLUID);
        changeTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK);
        changeLeftSiderbarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED);
    };

    return (
        <React.Fragment>
            <div className="p-3">
                <div className="alert alert-warning" role="alert">
                    <strong>Customize </strong> the overall color scheme, sidebar menu, etc.
                </div>

                <h5 className="mt-3">Layout</h5>
                <hr className="mt-1" />

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="layout"
                        value={layoutConstants.LAYOUT_VERTICAL}
                        id="vertical-check"
                        onChange={(e) => changeLayoutType(e.currentTarget.value)}
                        checked={layoutType === layoutConstants.LAYOUT_VERTICAL}
                    />
                    <label className="custom-control-label" htmlFor="vertical-check">
                        Vertical Layout
                    </label>
                </div>

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="layout"
                        value={layoutConstants.LAYOUT_HORIZONTAL}
                        id="horizontal-check"
                        onChange={(e) => changeLayoutType(e.currentTarget.value)}
                        checked={layoutType === layoutConstants.LAYOUT_HORIZONTAL}
                    />
                    <label className="custom-control-label" htmlFor="horizontal-check">
                        Horizontal Layout
                    </label>
                </div>

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="layout"
                        value={layoutConstants.LAYOUT_DETACHED}
                        id="detached-check"
                        onChange={(e) => changeLayoutType(e.currentTarget.value)}
                        checked={layoutType === layoutConstants.LAYOUT_DETACHED}
                    />
                    <label className="form-check-label" htmlFor="detached-check">
                        Detached Layout
                    </label>
                </div>

                <h5 className="mt-4">Width</h5>
                <hr className="mt-1" />

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="width"
                        value={layoutConstants.LAYOUT_WIDTH_FLUID}
                        id="fluid-check"
                        checked={layoutWidth === layoutConstants.LAYOUT_WIDTH_FLUID}
                        onChange={(e) => changeWidthMode(e.currentTarget.value)}
                        disabled={disablelayoutWidth}
                    />
                    <label className="form-check-label" htmlFor="fluid-check">
                        Fluid
                    </label>
                </div>

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="width"
                        value={layoutConstants.LAYOUT_WIDTH_BOXED}
                        id="boxed-check"
                        checked={layoutWidth === layoutConstants.LAYOUT_WIDTH_BOXED}
                        onChange={(e) => changeWidthMode(e.currentTarget.value)}
                        disabled={disablelayoutWidth}
                    />
                    <label className="form-check-label" htmlFor="boxed-check">
                        Boxed
                    </label>
                </div>

                <h5 className="mt-4">Left Sidebar</h5>
                <hr className="mt-1" />

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="theme"
                        value={layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT}
                        id="default-check"
                        onChange={(e) => changeTheme(e.currentTarget.value)}
                        checked={leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT}
                        disabled={disableSidebarTheme}
                    />
                    <label className="form-check-label" htmlFor="default-theme-check">
                        Default
                    </label>
                </div>

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="theme"
                        value={layoutConstants.LEFT_SIDEBAR_THEME_LIGHT}
                        id="light-check"
                        onChange={(e) => changeTheme(e.currentTarget.value)}
                        checked={leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_LIGHT}
                        disabled={disableSidebarTheme}
                    />
                    <label className="form-check-label" htmlFor="light-theme-check">
                        Light
                    </label>
                </div>

                <div className="form-check form-switch mb-3">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="theme"
                        value={layoutConstants.LEFT_SIDEBAR_THEME_DARK}
                        id="dark-check"
                        onChange={(e) => changeTheme(e.currentTarget.value)}
                        checked={leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_DARK}
                        disabled={disableSidebarTheme}
                    />
                    <label className="form-check-label" htmlFor="dark-theme-check">
                        Dark
                    </label>
                </div>

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="compact"
                        value={layoutConstants.LEFT_SIDEBAR_TYPE_FIXED}
                        id="fixed-check"
                        checked={leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_FIXED}
                        onChange={(e) => changeLeftSiderbarType(e.currentTarget.value)}
                        disabled={disableSidebarType}
                    />
                    <label className="form-check-label" htmlFor="fixed-check">
                        Fixed
                    </label>
                </div>

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="compact"
                        value={layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED}
                        id="condensed-check"
                        onChange={(e) => changeLeftSiderbarType(e.currentTarget.value)}
                        checked={leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED}
                        disabled={disableSidebarType}
                    />
                    <label className="form-check-label" htmlFor="condensed-check">
                        Condensed
                    </label>
                </div>

                <div className="form-check form-switch mb-1">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="compact"
                        value={layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE}
                        id="scrollable-check"
                        onChange={(e) => changeLeftSiderbarType(e.currentTarget.value)}
                        checked={leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE}
                        disabled={disableSidebarType}
                    />
                    <label className="form-check-label" htmlFor="scrollable-check">
                        Scrollable
                    </label>
                </div>

                <div className="d-grid mt-4">
                    <button className="btn btn-primary" id="resetBtn" onClick={reset}>
                        Reset to Default
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ThemeCustomizer;
