import { APICore } from "./apiCore"

const api = new APICore()
const baseApiurlPersons = process.env.REACT_APP_PERSONS_URL
const baseApiurlAccomodation = process.env.REACT_APP_ACCOMMODATION_URL
const baseApiurlTransport = process.env.REACT_APP_TRANSPORT_URL

const downloadFile = (data, filename, mime, bom) => {
  var blobData = typeof bom !== "undefined" ? [bom, data] : [data]
  var blob = new Blob(blobData, { type: mime || "application/octet-stream" })

  if (typeof window.navigator.msSaveBlob !== "undefined") {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob)
    var tempLink = document.createElement("a")
    tempLink.style.display = "none"
    tempLink.href = blobURL
    tempLink.setAttribute("download", filename)
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank")
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    setTimeout(function () {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 200)
  }
}

const apiCallgenders = () => {
  const baseUrl = baseApiurlPersons + "/lists/genders"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallGendersIdentity = () => {
  const baseUrl = baseApiurlPersons + "/lists/genderIdentities"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallIdentityTypes = () => {
  const baseUrl = baseApiurlPersons + "/lists/identityTypes"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallNationalities = () => {
  const baseUrl = baseApiurlPersons + "/lists/nationalities"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallEthnicities = () => {
  const baseUrl = baseApiurlPersons + "/lists/ethnicities"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallCountries = () => {
  const baseUrl = baseApiurlPersons + "/region/countries"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallColombianDepartments = () => {
  const baseUrl = baseApiurlPersons + "/region/departments"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallDepartmentsByCountryCode = (countryCode) => {
  const baseUrl = `${baseApiurlPersons}/region/departments/search`
  return api.get(`${baseUrl}`, { countryId: countryCode }).then((res) => res.data)
}

const apiCallCities = () => {
  const baseUrl = baseApiurlPersons + "/region/cities"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallCitiesByDepartment = (departmentCode) => {
  const baseUrl = `${baseApiurlPersons}/region/cities?departmentCode=${departmentCode}`
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallInstitutes = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/institutes"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallRelationShips = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/relationships"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

// SERVICES PRO
/*const apiCallBeneficiaryType = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/beneficiaryTypes"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallDisabilitiesType = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/disabilities"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallHomeRolesType = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/homeRoles"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallVulnerabilitiesType = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/vulnerabilities"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallEtpvInformationsType = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/etpvInformations"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallEtpvOrientationsType = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/etpvOrientations"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

// SERVICE PRO - SERVICES
const apiCallInformationServices = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/informationServices"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallKitServicesGiven = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/kitServicesGiven"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallKitServices = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/kitServices"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallMigrationSituations = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/migrationSituations"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}

const apiCallMigrationProfiles = () => {
  const baseUrl = baseApiurlAccomodation + "/lists/migrationProfiles"
  try {
    return api.get(`${baseUrl}`)
      .then((res) => res.data)
      .catch(error => {
        console.error(error)
        return [];
      })
  } catch (err) {
    return []
  }
}*/

const apiCallServicesPro = async (key) => {
  const baseUrl = baseApiurlPersons + `/lists/${key}`
  try {
    return await api
      .get(`${baseUrl}`)
      .then((res) => res.data)
      .catch((error) => {
        console.error(error)
        return []
      })
  } catch (err) {
    return []
  }
}

const postTransportRequest = (request) => {
  const baseUrl = `${baseApiurlTransport}/registries`
  try {
    return api.create(`${baseUrl}`, request)
  } catch (error) {
    console.error(error)
  }
}

const apiCallReasonsTransport = () => {
  const baseUrl = baseApiurlTransport + "/lists/reasons"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

const apiCallTypeTransport = () => {
  const baseUrl = baseApiurlTransport + "/lists/modes"
  return api.get(`${baseUrl}`).then((res) => res.data)
}

export {
  downloadFile,
  apiCallgenders,
  apiCallIdentityTypes,
  apiCallNationalities,
  apiCallEthnicities,
  apiCallCountries,
  apiCallColombianDepartments,
  apiCallDepartmentsByCountryCode,
  apiCallCities,
  apiCallCitiesByDepartment,
  apiCallInstitutes,
  apiCallGendersIdentity,
  postTransportRequest,
  apiCallServicesPro,
  apiCallReasonsTransport,
  apiCallTypeTransport,
  apiCallRelationShips,
}
