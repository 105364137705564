import { APICore } from "../api/apiCore"

//Variables de configuracion
const api = new APICore()
const baseApiurl = process.env.REACT_APP_PERSONS_URL
const baseApiUrlReports = process.env.REACT_APP_REPORTS_URL

const getPersonsByFilters = (params = { limit: 5, page: 1 }) => {
  params = { limit: params?.limit ?? 5, page: params?.page ?? 1, ...params }
  const baseUrl = baseApiurl + "/migrants"
  return api.get(`${baseUrl}`, { ...params })
}

const getPersonById = (id) => {
  const baseUrl = baseApiurl + `/migrants/${id}`
  return api.get(`${baseUrl}`)
}

const getPersonBulkById = (list = [1]) => {
  const baseUrl = baseApiurl + `/migrants/list`
  return api.create(`${baseUrl}`, { list })
}

const createPerson = (data) => {
  const baseUrl = baseApiurl + `/migrants`
  return api.create(`${baseUrl}`, data)
}

const postSearchBybiometry = (data) => {
  const baseUrl = baseApiurl + `/migrants/faceRecognition`
  return api.create(`${baseUrl}`, data)
}

const postUploadImageBybiometry = (data, callback) => {
  const baseUrl = baseApiurl + `/files/face`
  return api.create(`${baseUrl}`, data)
}

const updatedMigrantInfo = (id, data, callback) => {
  const baseUrl = baseApiurl + `/migrants/${id}`
  return api.updatePatch(`${baseUrl}`, data)
}

const postRegisterFormPro = (data, callback) => {
  const baseUrl = baseApiurl + `/pro/registries`
  return api
    .create(`${baseUrl}`, data)
    .then((d) => d)
    .catch((error) => null)
}

const patchRegisterFormPro = (data, id, callback) => {
  const baseUrl = baseApiurl + `/pro/registries/${id}`
  return api
    .updatePatch(`${baseUrl}`, data)
    .then((d) => d)
    .catch((error) => null)
}

const getPersons = (params) => {
  const baseUrl = baseApiurl + "/migrants"
  return api.get(`${baseUrl}`, params)
}

const getPersonsServicesHistory = (id) => {
  const baseUrl = baseApiUrlReports + `/migrant/${id}`
  return api.get(`${baseUrl}`)
}

const getRegistriesProByMigranID = (id) => {
  const baseUrl = baseApiurl + `/pro/registries/by-migrant-id/${id}`
  return api.get(`${baseUrl}`)
}

export {
  getPersons,
  getPersonsByFilters,
  getPersonById,
  createPerson,
  getPersonBulkById,
  postSearchBybiometry,
  postUploadImageBybiometry,
  updatedMigrantInfo,
  postRegisterFormPro,
  getPersonsServicesHistory,
  getRegistriesProByMigranID,
  patchRegisterFormPro,
}
