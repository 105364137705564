// @flow
import { combineReducers } from "redux"

import Auth from "./auth/reducers"
import Layout from "./layout/reducers"
import Utilities from "./utility/reducers"
import Beneficiario from "./persons/reducers"
import Accomodation from "./Accommodation/reducers"
import { Information } from "./information/reducers"
import NfiReducer from "./nfi/reducers"
import storage from "redux-persist/lib/storage"
import persistReducer from "redux-persist/es/persistReducer"
import transport from "./transport/reducers.ts"
import hardSet from "redux-persist/es/stateReconciler/hardSet"
import ProReducer from "./Pro/reducers"

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
}

const rootReducer = combineReducers({
  Auth,
  Layout,
  Utilities,
  Beneficiario,
  Accomodation,
  Information,
  nfi: NfiReducer,
  transport,
  ProReducer,
})

export default persistReducer(persistConfig, rootReducer)
