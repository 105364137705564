// @flow
import { APICore } from "./apiCore"

const api = new APICore()
const baseApiurlPersons = process.env.REACT_APP_AUTH_URL
const baseApiurlUsersAuth = process.env.REACT_APP_AUTH_USERS_URL
const baseApiUsers = process.env.REACT_APP_USER_URL

// account
function apiCreateUser(params) {
  const baseUrl = `${baseApiurlUsersAuth}users`
  return api.create(`${baseUrl}`, params)
}

function login(params) {
  const baseUrl = baseApiurlPersons + "/login/"
  return api.createWithAuth(`${baseUrl}`, params, { headers: { "public-request": true } })
}

function logout() {
  const baseUrl = baseApiurlPersons + "/logout/"
  return api.create(`${baseUrl}`)
}

function signup(params) {
  const baseUrl = "/register/"
  return api.create(`${baseUrl}`, params)
}

function forgotPassword(params) {
  const baseUrl = "/forget-password/"
  return api.create(`${baseUrl}`, params)
}

function forgotPasswordConfirm(params) {
  const baseUrl = "/password/reset/confirm/"
  return api.create(`${baseUrl}`, params)
}

function refreshToken(headers) {
  const baseUrl = `${baseApiurlUsersAuth}token/refresh`
  return api.get(`${baseUrl}`, null, headers)
}

// ENTITIES LIST
function apiUserEntities(entityType) {
  const baseUrl = `${baseApiUsers}/entities?type=${entityType}`
  return api.get(`${baseUrl}`)
}

function apiGetUserEntity(entityId) {
  const baseUrl = `${baseApiUsers}/entities/${entityId}`
  return api.get(`${baseUrl}`)
}

const updateStatusUser = (username, data) => {
  const baseUrl = `${baseApiUsers}/users/${username}/active`
  return api.updatePatch(baseUrl, data)
}

export {
  apiCreateUser,
  login,
  logout,
  signup,
  forgotPassword,
  forgotPasswordConfirm,
  refreshToken,
  apiUserEntities,
  apiGetUserEntity,
  updateStatusUser,
}
