import { BrowserRouter, Route, Switch } from "react-router-dom"
import { useSelector } from "react-redux"
import * as layoutConstants from "../constants/layout"
import ScrollTop from "../components/ScrollTop/ScrollTop"

// All layouts/containers
import DefaultLayout from "../layouts/Default"
import VerticalLayout from "../layouts/Vertical"
import DetachedLayout from "../layouts/Detached"
import HorizontalLayout from "../layouts/Horizontal/"
import React from "react"

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from "./index"
import { FloatIconPersons } from "../components/FloatIconPerson/FloatIconPersons"

const Routes = React.memo((props) => {
  const { layout, user } = useSelector((state) => ({
    layout: state.Layout,
    user: state.Auth.user,
  }))

  const getLayout = () => {
    let layoutCls = VerticalLayout

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout
        break
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Switch>
          <Route path={publicProtectedFlattenRoutes.map((r) => r["path"])}>
            <DefaultLayout {...props} layout={layout}>
              <Switch>
                {publicProtectedFlattenRoutes.map((route, index) => {
                  return (
                    !route.children && (
                      <route.route
                        key={index}
                        path={route.path}
                        roles={route.roles}
                        exact={route.exact}
                        component={route.component}
                      />
                    )
                  )
                })}
              </Switch>
            </DefaultLayout>
          </Route>

          <Route path={authProtectedFlattenRoutes.map((r) => r["path"])}>
            <Layout {...props} layout={layout} user={user}>
              <Switch>
                {authProtectedFlattenRoutes.map((route, index) => {
                  return (
                    !route.children && (
                      <route.route
                        key={index}
                        path={route.path}
                        roles={route.roles}
                        permissions={route.access}
                        exact={route.exact}
                        component={route.component}
                      />
                    )
                  )
                })}
              </Switch>
              <FloatIconPersons />
            </Layout>
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  )
})

export default Routes
