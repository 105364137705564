export interface Migrant {
    id?: number;
    name: string;
    lastName: string;
    birthDate: Date | string;
    isAccompanied?: boolean;
    documentTypeId?: number;
    nationalityId: number;
    genderId: number;
    gender?: {
        id: number,
        name: string,
    };
    ethnicityId?: number;
    photoUrl?: string;
}

export enum PersonTypeNationality {
    VENEZOLANO = 1,
    COLOMBIANO = 2,
    COLOMBOVENEZOLANO = 3,
    OTRO = 4
}

export enum GroupTypeVulnerations {
    BABY,
    BOY,
    GIRL,
    CHILD,
    TEENAGE_BOY,
    TEENAGE_GIRL,
    TEENAGE,
    WOMAN,
    MAN,
    ADULT,
    GRANDPARENT_MAN,
    GRANDPARENT_WOMAN,
    GRANDPARENT,
    ALL,
    OTHER,
    DISCAPACITY,
}

export enum GroupTypeIdentities {
    I_CHILD,
    I_TEEN,
    I_ADULT,
    I_ALL,
}