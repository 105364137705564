import React from "react"
import { Route, Redirect } from "react-router-dom"
import { PROFILE_MONITOR, ROLES } from "../constants/auth"
const PrivateRoute = React.lazy(() => import("./PrivateRoute"))

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/account/Login"))
const Logout = React.lazy(() => import("../pages/account/Logout"))
const Register = React.lazy(() => import("../pages/account/Register"))
const Confirm = React.lazy(() => import("../pages/account/Confirm"))
const ForgetPassword = React.lazy(() => import("../pages/account/ForgetPassword"))
const LockScreen = React.lazy(() => import("../pages/account/LockScreen"))

//Dashboard
const Monitoring = React.lazy(() => import("../pages/monitoring"))

//Serices PRO - Persons
const SearchPerson = React.lazy(() => import("../pages/persons/search/SearchPerson.tsx"))
const SearchHistoryPerson = React.lazy(() => import("../pages/persons/history/search"))
const BeneficiaryDetail = React.lazy(() => import("../pages/persons/history/detail"))
const SearchPersonByNames = React.lazy(() =>
  import("../pages/persons/search/SearchPersonByNames.tsx")
)
const Listbeneficiaries = React.lazy(() => import("../pages/persons/Lits/Listbeneficiaries"))
const SearchPersonManagement = React.lazy(() =>
  import("../pages/persons/search/SearchPersonManagement.tsx")
)
const SearchByBiometry = React.lazy(() => import("../pages/persons/search/SearchByBiometry.tsx"))
const SuccesCreateServicesPro = React.lazy(() =>
  import("../pages/servicesPRO/create/Components/SuccesCreateServicesPro")
)
const InformationSuccesPage = React.lazy(() => import("../pages/information/successInformation"))
const BeneficiarySummary = React.lazy(() => import("../pages/servicesPRO/BeneficiarySummary/"))
const RegisterServicesPro = React.lazy(() => import("../pages/servicesPRO/create"))
const ListServicesPro = React.lazy(() => import("../pages/servicesPRO/list/ListServicesPro"))
const DetailServicesPro = React.lazy(() => import("../pages/servicesPRO/detail"))
const EditServicesPro = React.lazy(() => import("../pages/servicesPRO/edit"))

const CreatePerson = React.lazy(() => import("../pages/persons/create"))
const PersonDetail = React.lazy(() => import("../pages/persons/detail"))
const PersonEdit = React.lazy(() => import("../pages/persons/edit"))

// NFI
const ListAsignation = React.lazy(() => import("../pages/NFI/asignations/ListAsignations"))
const createDealer = React.lazy(() => import("../pages/NFI/dealer/dealer.component"))
const createUserDealer = React.lazy(() => import("../pages/NFI/dealer/user-dealer.component"))
const createAsignation = React.lazy(() =>
  import("../pages/NFI/asignations/create-asignation/create-asignation.component")
)
const detailAsignation = React.lazy(() => import("../pages/NFI/asignations/detail-asignation"))
const registerServiceAsignation = React.lazy(() =>
  import("../pages/NFI/asignations/service-asignation")
)
const SearchPersonNFI = React.lazy(() => import("../pages/NFI/asignations/search"))
const CreateDeliveryAsignation = React.lazy(() =>
  import("../pages/NFI/asignations/create-delivery-asignation")
)
const EditRegisterDeliveryNFI = React.lazy(() =>
  import("../pages/NFI/register-delivery/edit-register-delivery")
)
const DetailRegisterDeliveryNFI = React.lazy(() =>
  import("../pages/NFI/register-delivery/detail-register-delivery")
)
const ListRegisterDeliveryNFI = React.lazy(() =>
  import("../pages/NFI/register-delivery/list-register-delivery")
)

//Accommodation
const ListRequest = React.lazy(() => import("../pages/accommodation/Request/ListRequest"))
const ListAccommodations = React.lazy(() =>
  import("../pages/accommodation/list/ListAccommodations")
)
const RequestDetail = React.lazy(() => import("../pages/accommodation/Request/Detail"))
const CreateRequest = React.lazy(() => import("../pages/accommodation/Request/Create"))
const SearchPersonAccomodation = React.lazy(() =>
  import("../pages/accommodation/search/SearchAccommodation")
)
const SearchPersonAccomodationByNames = React.lazy(() =>
  import("../pages/accommodation/search/SearchPersonAccommodationByNames")
)
const IngressAccommodation = React.lazy(() => import("../pages/accommodation/ingress"))
const ExitFullAccommodation = React.lazy(() => import("../pages/accommodation/exit"))
const PartialExitAccommodation = React.lazy(() => import("../pages/accommodation/partialExit"))
const ListRegisterAccomodation = React.lazy(() => import("../pages/accommodation/register"))
const IngressAdditional = React.lazy(() =>
  import("../pages/accommodation/ingress/additionalIngress")
)
const PartialSaveAccommodation = React.lazy(() =>
  import("../pages/accommodation/partialSave/PartialSaveAccommodation")
)
const DetailRegisterAccomodation = React.lazy(() =>
  import("../pages/accommodation/register/Detail/DetailRegisterAccomodation")
)
const ServiceRegisterAccomodation = React.lazy(() =>
  import("../pages/accommodation/RegisterServices")
)
const EditBookingAccomodation = React.lazy(() =>
  import("../pages/accommodation/register/Edit/EditBookingAccomodation")
)

//Transport
const ListRequestTransport = React.lazy(() => import("../pages/transport/listRequest"))
const DetailRequestTransport = React.lazy(() => import("../pages/transport/detailRequest"))
const ServiceTransportLocal = React.lazy(() => import("../pages/transport/create/service-local"))
const ServiceTransportNational = React.lazy(() =>
  import("../pages/transport/create/service-national")
)
const ServiceTransportFrontier = React.lazy(() =>
  import("../pages/transport/create/frontier-to-frontier-service")
)
const SearchPersonTransport = React.lazy(() => import("../pages/transport/create/search"))

//family
const FamilyDetail = React.lazy(() => import("../pages/family/detail/index"))

// Users
const ListUsers = React.lazy(() => import("../pages/users/List"))
const RegisterUser = React.lazy(() => import("../pages/users/Register"))
const RegisterEntitie = React.lazy(() => import("../pages/users/RegisterEntitie"))

//Reports
const Reports = React.lazy(() => import("../pages/reports"))

// root routes
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
}

const usersRoutes = {
  path: "/users",
  name: "Usuarios",
  roles: ["Admin", "PF NFI"],
  header: "OIM",
  route: PrivateRoute,
  children: [
    {
      path: "/users",
      name: "Listado de usuarios del sistema",
      route: PrivateRoute,
      component: ListUsers,
      exact: true,
    },
    {
      path: "/users/create",
      name: "Registrar usuarios del sistema",
      route: PrivateRoute,
      component: RegisterUser,
      exact: true,
      access: [ROLES.SUPERVISION_NFI],
    },
    {
      path: "/users/create/entitie",
      name: "Registrar Entidades asociada a usuarios",
      route: PrivateRoute,
      component: RegisterEntitie,
      exact: true,
    },
    {
      path: "/users/edit/:id",
      name: "Editar usuarios del sistema",
      route: PrivateRoute,
      component: RegisterUser,
      exact: true,
    },
  ],
}

const servicesPRORoutes = {
  path: "/services-pro",
  name: "Servicios PRO",
  roles: ["Admin"],
  header: "OIM",
  route: PrivateRoute,
  children: [
    {
      path: "/services-pro/search",
      name: "Consulta personas servicios PRO",
      route: PrivateRoute,
      component: SearchPerson,
      exact: true,
    },
    {
      path: "/services-pro/search-by-names",
      name: "Consulta personas por nombres",
      route: PrivateRoute,
      component: SearchPersonByNames,
      exact: true,
    },
    {
      path: "/services-pro/search-by-biometry",
      name: "Consulta personas por biometria",
      route: PrivateRoute,
      component: SearchByBiometry,
      exact: true,
    },
    {
      path: "/services-pro/member-list",
      name: "Resumen de beneficiarios",
      route: PrivateRoute,
      component: BeneficiarySummary,
      exact: true,
    },
    {
      path: "/services-pro/create",
      name: "Registro servicios PRO",
      route: PrivateRoute,
      component: RegisterServicesPro,
      exact: true,
    },
    {
      path: "/services-pro/create/succes",
      name: "Registro servicios PRO exitoso",
      route: PrivateRoute,
      component: SuccesCreateServicesPro,
      exact: true,
    },
    {
      path: "/information/success",
      name: "Registro servicio exitoso",
      route: PrivateRoute,
      component: InformationSuccesPage,
      exact: true,
    },
    {
      path: "/services-pro/edit/:id",
      name: "Registro servicios PRO",
      route: PrivateRoute,
      component: PersonEdit,
      exact: true,
    },
    {
      path: "/services-pro/list",
      name: "Lista de formularios registrados pro",
      route: PrivateRoute,
      component: ListServicesPro,
      exact: true,
    },
    {
      path: "/services-pro/detail/:id",
      name: "Detalle Formulario servicios PRO",
      route: PrivateRoute,
      component: DetailServicesPro,
      exact: true,
    },
    {
      path: "/services-pro/form/edit/:id",
      name: "Edición Formulario servicios PRO",
      route: PrivateRoute,
      component: EditServicesPro,
      exact: true,
    },
  ],
}

const appRoutes = [
  {
    path: "/dashboard",
    name: "Seguimiento",
    route: PrivateRoute,
    roles: [
      "Admin",
      "PF PRO",
      "PRO",
      "PF Alojamiento",
      "Alojamiento",
      "PF NFI",
      "NFI Dealer",
      PROFILE_MONITOR,
      "UNIDAD ESPECIALIZADA",
      "ENLACE TERRITORIAL",
    ],
    icon: "uil-chart",
    component: Monitoring,
    header: "Seguimiento",
  },
  {
    path: "/reports",
    name: "Reportes",
    route: PrivateRoute,
    roles: [
      "Admin",
      "PF PRO",
      "PRO",
      "PF Alojamiento",
      "Alojamiento",
      "PF NFI",
      "NFI Dealer",
      "Transporte",
      "PF Transporte",
      PROFILE_MONITOR,
      "UNIDAD ESPECIALIZADA",
      "ENLACE TERRITORIAL",
    ],
    icon: "dripicons-clipboard",
    component: Reports,
    header: "Reportes",
  },
  {
    path: "/persons",
    name: "Registro de personas",
    route: PrivateRoute,
    roles: ["Admin", "PRO"],
    icon: "uil-document-layout-center",
    header: "OIM",
    children: [
      {
        path: "/persons/search",
        name: "Consulta personas",
        route: PrivateRoute,
        component: SearchPerson,
        access: [ROLES.CONSULTA_PERSONA],
      },
      {
        path: "/persons/create",
        name: "Creación de persona",
        route: PrivateRoute,
        component: CreatePerson,
        access: [ROLES.REGISTRO_PERSONA],
        exact: true,
      },
      {
        path: "/persons/:id",
        name: "Detalle de persona",
        route: PrivateRoute,
        component: PersonDetail,
        exact: true,
        access: [ROLES.CONSULTA_PERSONA],
      },
    ],
  },
  {
    path: "/beneficiary",
    name: "Consulta beneficiarios",
    route: PrivateRoute,
    roles: [
      "Admin",
      "PF PRO",
      "PRO",
      "PF Alojamiento",
      "Alojamiento",
      "PF NFI",
      "PF Transporte",
      PROFILE_MONITOR,
      "UNIDAD ESPECIALIZADA",
      "ENLACE TERRITORIAL",
    ],
    icon: "uil-search",
    header: "OIM",
    children: [
      {
        path: "/beneficiary/search",
        name: "Consulta historico beneficiarios",
        route: PrivateRoute,
        component: SearchHistoryPerson,
        access: [ROLES.HISTORIAL_BENEFICIARIO, ROLES.SUPERVISION_TRANSPORTE],
        exact: true,
      },
      {
        path: "/beneficiary/management",
        name: "Manejo de beneficiarios",
        route: PrivateRoute,
        component: SearchPersonManagement,
        access: [ROLES.CONSULTA_PERSONA, ROLES.REGISTRO_PERSONA],
        exact: true,
      },
      {
        path: "/beneficiary/search/search-by-names",
        name: "Consulta historico beneficiarios por nombres y apellidos",
        route: PrivateRoute,
        component: SearchPersonByNames,
        access: [ROLES.CONSULTA_PERSONA],
        exact: true,
      },
      {
        path: "/beneficiary/list",
        name: "Listado de beneficiarios registrados",
        route: PrivateRoute,
        component: Listbeneficiaries,
        access: [ROLES.HISTORIAL_BENEFICIARIO, ROLES.SUPERVISION_TRANSPORTE],
        exact: true,
      },
      {
        path: "/beneficiary/:id",
        name: "Detalle historico beneficiario",
        route: PrivateRoute,
        component: BeneficiaryDetail,
        access: [ROLES.CONSULTA_PERSONA],
        exact: true,
      },
      {
        path: "/beneficiary/edit/:id",
        name: "Detalle historico beneficiario",
        route: PrivateRoute,
        component: PersonEdit,
        access: [ROLES.CONSULTA_PERSONA],
        exact: true,
      },
      {
        path: "/beneficiary/search/search-by-biometry",
        name: "Consulta historico beneficiarios por nombres y apellidos",
        route: PrivateRoute,
        component: SearchByBiometry,
        access: [ROLES.CONSULTA_PERSONA],
        exact: true,
      },
    ],
  },
  {
    path: "/accommodation",
    name: "Listado solicutdes de alojamiento",
    route: PrivateRoute,
    roles: ["Admin", "PF PRO", "PRO", "PF Alojamiento", "Alojamiento"],
    icon: "uil-bed",
    header: "OIM",
    children: [
      {
        path: "/accommodation/search",
        name: "Consulta hogar registro alojamiento",
        route: PrivateRoute,
        component: SearchPersonAccomodation,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        exact: true,
      },
      {
        path: "/accommodation/search/by-names",
        name: "Consulta personas servicios PRO",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: SearchPersonAccomodationByNames,
        exact: true,
      },
      {
        path: "/accommodation/search-by-biometry",
        name: "Consulta personas servicios PRO",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: SearchByBiometry,
        exact: true,
      },
      {
        path: "/accommodation/family/ingress",
        name: "Ingreso de hogar",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: IngressAccommodation,
        exact: true,
      },
      {
        path: "/accommodation/family/ingress/additional",
        name: "Ingreso adicional",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: IngressAdditional,
        exact: true,
      },
      {
        path: "/accommodation/family/exit-total",
        name: "Ingreso de hogar",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: ExitFullAccommodation,
        exact: true,
      },
      {
        path: "/accommodation/family/partial-exit",
        name: "Salida parcial de hogar",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: PartialExitAccommodation,
        exact: true,
      },
      {
        path: "/accommodation/family/:id",
        name: "Detalle miembros del hogar",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: FamilyDetail,
        exact: true,
      },
      {
        path: "/accommodation/list",
        name: "Listado de alojamientos",
        route: PrivateRoute,
        access: [
          ROLES.REGISTRO_ALOJAMIENTO,
          ROLES.REGISTRO_PRO,
          ROLES.SUPERVISION_ALOJAMIENTO,
          ROLES.SUPERVISION_PRO,
          ROLES.REGISTRO_PRO,
        ],
        component: ListAccommodations,
      },
      {
        path: "/accommodation/request",
        name: "Listado solicitudes de alojamiento",
        route: PrivateRoute,
        access: [
          ROLES.REGISTRO_ALOJAMIENTO,
          ROLES.SUPERVISION_PRO,
          ROLES.REGISTRO_PRO,
          ROLES.SUPERVISION_ALOJAMIENTO,
        ],
        component: ListRequest,
        exact: true,
      },
      {
        path: "/accommodation/request/create",
        name: "Crear Servicio",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: CreateRequest,
        exact: true,
      },
      {
        path: "/accommodation/request/:id",
        name: "Detalle solicitud de alojamiento",
        route: PrivateRoute,
        access: [
          ROLES.REGISTRO_ALOJAMIENTO,
          ROLES.SUPERVISION_ALOJAMIENTO,
          ROLES.SUPERVISION_PRO,
          ROLES.REGISTRO_PRO,
        ],
        component: RequestDetail,
        exact: true,
      },

      {
        path: "/accommodation/bookings",
        name: "Listado de reservas",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO, ROLES.SUPERVISION_ALOJAMIENTO],
        component: ListRegisterAccomodation,
        exact: true,
      },
      {
        path: "/accommodation/bookings/detail/:id/checkIn",
        name: "Detalle de servicio alojamiento",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO, ROLES.SUPERVISION_ALOJAMIENTO],
        component: DetailRegisterAccomodation,
        exact: true,
      },
      {
        path: "/accommodation/bookings/detail/:id/checkOut",
        name: "Detalle de servicio alojamiento",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO, ROLES.SUPERVISION_ALOJAMIENTO],
        component: DetailRegisterAccomodation,
        exact: true,
      },

      {
        path: "/accommodation/bookings/services-registre",
        name: "Registro de servicios",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO, ROLES.SUPERVISION_ALOJAMIENTO],
        component: ServiceRegisterAccomodation,
        exact: true,
      },
      {
        path: "/accommodation/bookings/detail/:id/partial-save",
        name: "Registro servicios adicionales",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: PartialSaveAccommodation,
        exact: true,
      },
      {
        path: "/accommodation/bookings/edit/:id",
        name: "Editar reserva de alojamiento",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_ALOJAMIENTO],
        component: EditBookingAccomodation,
        exact: true,
      },
    ],
  },
  {
    path: "/nfi",
    name: "NFI",
    route: PrivateRoute,
    roles: ["Admin", "PF NFI", "NFI Dealer"],
    icon: "uil-medkit",
    header: "NFi",
    children: [
      {
        path: "/nfi/create/dealer",
        name: "Creación de dealer",
        route: PrivateRoute,
        component: createDealer,
        access: [ROLES.SUPERVISION_NFI],
        exact: true,
      },
      {
        path: "/nfi/create/dealer/user",
        name: "Creación de usuario",
        route: PrivateRoute,
        component: createUserDealer,
        access: [ROLES.SUPERVISION_NFI],
        exact: true,
      },
      {
        path: "/nfi/asignations",
        name: "Listado de asignaciones",
        route: PrivateRoute,
        component: ListAsignation,
        access: [ROLES.SUPERVISION_NFI, ROLES.REGISTRO_NFI],
        exact: true,
      },
      {
        path: "/nfi/create/asignations",
        name: "Creacion de asignación",
        route: PrivateRoute,
        component: createAsignation,
        access: [ROLES.SUPERVISION_NFI],
        exact: true,
      },
      {
        path: "/nfi/asignations/detail/:id",
        name: "Detalle de asignación",
        route: PrivateRoute,
        component: detailAsignation,
        access: [ROLES.SUPERVISION_NFI, ROLES.REGISTRO_NFI],
        exact: true,
      },
      {
        path: "/nfi/service/select-asignations",
        name: "Selección de asignación para entrega",
        route: PrivateRoute,
        component: registerServiceAsignation,
        access: [ROLES.REGISTRO_NFI],
        exact: true,
      },
      {
        path: "/nfi/register-delivery/list",
        name: "Lista de formularios NFI",
        route: PrivateRoute,
        component: ListRegisterDeliveryNFI,
        exact: true,
      },
      {
        path: "/nfi/register-delivery/edit/:id",
        name: "Editar registro de entrega de NFI",
        route: PrivateRoute,
        component: EditRegisterDeliveryNFI,
        exact: true,
      },
      {
        path: "/nfi/register-delivery/detail/:id",
        name: "Detalle registro de entrega de NFI",
        route: PrivateRoute,
        component: DetailRegisterDeliveryNFI,
        exact: true,
      },
      {
        path: "/nfi/service/create",
        name: "Creacion de servicio nfi para entrega",
        route: PrivateRoute,
        component: CreateDeliveryAsignation,
        access: [ROLES.SUPERVISION_NFI, ROLES.REGISTRO_NFI],
        exact: true,
      },
      {
        path: "/nfi/service/search",
        name: "Consulta personas servicios NFI",
        route: PrivateRoute,
        component: SearchPersonNFI,
        access: [ROLES.SUPERVISION_NFI, ROLES.REGISTRO_NFI],
        exact: true,
      },
      {
        path: "/nfi/search-by-biometry",
        name: "Consulta personas NFI",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_NFI],
        component: SearchByBiometry,
        exact: true,
      },

      {
        path: "/nfi/search-by-names",
        name: "Consulta personas por nombres NFI",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_NFI],
        component: SearchPersonByNames,
        exact: true,
      },
    ],
  },
  {
    path: "/transport",
    name: "Transporte",
    route: PrivateRoute,
    roles: ["Admin", "PRO"],
    icon: "mdi mdi-bus",
    header: "Transporte",
    children: [
      {
        path: "/transport/request",
        name: "Listado solicitudes transporte",
        route: PrivateRoute,
        component: ListRequestTransport,
        access: [
          ROLES.SUPERVISION_TRANSPORTE,
          ROLES.REGISTRO_TRANSPORTE,
          ROLES.SUPERVISION_PRO,
          ROLES.REGISTRO_PRO,
          ROLES.REGISTRO_ALOJAMIENTO,
        ],
        exact: true,
      },
      {
        path: "/transport/search-by-names",
        name: "Consulta personas transporte",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_TRANSPORTE, ROLES.SUPERVISION_PRO],
        component: SearchPersonAccomodationByNames,
        exact: true,
      },
      {
        path: "/transport/search-by-biometry",
        name: "Consulta personas por biometría",
        route: PrivateRoute,
        access: [ROLES.REGISTRO_TRANSPORTE, ROLES.SUPERVISION_PRO],
        component: SearchByBiometry,
        exact: true,
      },
      {
        path: "/transport/request/:id",
        name: "Detalle solicitud transporte",
        route: PrivateRoute,
        component: DetailRequestTransport,
        access: [
          ROLES.REGISTRO_TRANSPORTE,
          ROLES.SUPERVISION_PRO,
          ROLES.SUPERVISION_TRANSPORTE,
          ROLES.REGISTRO_PRO,
          ROLES.REGISTRO_ALOJAMIENTO,
        ],
        exact: true,
      },
      {
        path: "/transport/create/local",
        name: "Detalle solicitud transporte",
        route: PrivateRoute,
        component: ServiceTransportLocal,
        access: [ROLES.REGISTRO_TRANSPORTE, ROLES.REGISTRO_PRO, ROLES.REGISTRO_ALOJAMIENTO],
        exact: true,
      },
      {
        path: "/transport/create/national",
        name: "Detalle solicitud transporte",
        route: PrivateRoute,
        component: ServiceTransportNational,
        access: [ROLES.REGISTRO_TRANSPORTE, ROLES.REGISTRO_PRO, ROLES.REGISTRO_ALOJAMIENTO],
        exact: true,
      },

      {
        path: "/transport/create/frontier",
        name: "Detalle solicitud transporte",
        route: PrivateRoute,
        component: ServiceTransportFrontier,
        access: [ROLES.REGISTRO_TRANSPORTE, ROLES.REGISTRO_PRO, ROLES.REGISTRO_ALOJAMIENTO],
        exact: true,
      },
      {
        path: "/transport/service/search",
        name: "Consulta personas servicios transporte",
        route: PrivateRoute,
        component: SearchPersonTransport,
        access: [ROLES.SUPERVISION_TRANSPORTE, ROLES.REGISTRO_TRANSPORTE],
        exact: true,
      },
    ],
  },
]

const otherPublicRoutes = [
  {
    path: "/account/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
]

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = []

  routes = routes || []
  routes.forEach((item) => {
    flatRoutes.push(item)

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

// auth
const authRoutes = [
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/account/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/account/register",
    name: "Register",
    component: Register,
    route: Route,
  },
  {
    path: "/account/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/account/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
]

// All routes
const authProtectedRoutes = [usersRoutes, rootRoute, servicesPRORoutes, ...appRoutes]
const publicRoutes = [...authRoutes, ...otherPublicRoutes]

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes])
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes])

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
}
