import { all, fork, put, select, takeLatest, takeEvery, call } from "@redux-saga/core/effects"
import {
  getAccomodationsAditionalServicesList,
  getAccomodationsList,
} from "../../helpers/Accommodation"
import { getPersonBulkById } from "../../helpers/Persons"
import { clearPersonFamily } from "../actions"
import {
  actAccommodationAditionalServicesData,
  actClearAdditionalIngress,
  actSetAccommodationAditionalServicesUser,
  actSetAccommodationUser,
  clearSelectedAccommodationPersonType,
  storeAccommodationType,
} from "./actions"
//import { actAccommodationInfo } from "./actions"
import { accommodationTypes } from "./constants"

/**
 * Watchers
 */
export function* watchCallSetAccommodation() {
  yield takeLatest(accommodationTypes.ACCOMMODATION_TYPE, function* (data) {
    try {
      const oldType = yield select((state) => state.Accomodation.type)
      if (oldType === data.payload) return
      switch (oldType) {
        case 1:
          yield put(clearSelectedAccommodationPersonType())
          break
        case 3:
          yield put(actClearAdditionalIngress())
          break
        default:
          yield put(clearPersonFamily())
          break
      }

      yield put(storeAccommodationType(data.payload))
    } catch (error) {
      console.error("Se presentó un error, guardando la información")
    }
  })
}

export function* getAccommodationUser() {
  try {
    const { data } = yield call(getAccomodationsList)
    yield put(actSetAccommodationUser(data[0]))
  } catch (error) {
    return false
  }
}

export function* getAccommodationAditionalServicesInfoUser(action) {
  try {
    const services = action.payload.registries
    const list = services.reduce((response, current) => {
      response.push(current.migrantId)
      return response
    }, [])
    const { data } = yield call(getPersonBulkById, { list })
    services.map((s) => {
      s.person = data.find((e) => e.id === s.migrantId)
      s.breakfast = 0
      s.lunch = 0
      s.dinner = 0
      s.snack = 0
      s.biosecurityKit = 0
      s.personalHygieneKit = 0
      s.otherKit = 0
      return s
    })
    yield put(actAccommodationAditionalServicesData(services))
  } catch (error) {
    console.log(error)
    return false
  }
}

export function* getAccommodationAditionalServicesUser(action) {
  try {
    const params = action.payload
    const data = yield call(getAccomodationsAditionalServicesList, params)
    yield put(actSetAccommodationAditionalServicesUser(data.data))
  } catch (error) {
    return false
  }
}

export function* watchGetAccommodationUser() {
  yield takeEvery(accommodationTypes.START_GET_ACCOMMODATION_USER, getAccommodationUser)
}

export function* watchGetAccommodationAditionalServicesUser() {
  yield takeEvery(
    accommodationTypes.SET_ACCOMMODATION_ADITIONAL_SERVICES_USER,
    getAccommodationAditionalServicesInfoUser
  )
}

export function* watchStartGetAccommodationAditionalServicesUser() {
  yield takeEvery(
    accommodationTypes.START_GET_MIGRANT_ACCOMMODATION_ADITIONAL_SERVICES_USER,
    getAccommodationAditionalServicesUser
  )
}

function* AccomodationSaga() {
  yield all([
    fork(watchCallSetAccommodation),
    fork(watchGetAccommodationUser),
    fork(watchGetAccommodationAditionalServicesUser),
    fork(watchStartGetAccommodationAditionalServicesUser),
  ])
}

export default AccomodationSaga
