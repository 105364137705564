import { proTypes } from "./contants"

const initState = {
  detail: {},
}

function ProReducer(state = initState, action) {
  switch (action.type) {
    case proTypes.SET_FORM_DETAIL:
      return {
        ...state,
        detail: action.payload,
      }
    case proTypes.CLEAN_SET_FORM_DETAIL:
      return initState
    default:
      return state
  }
}

export default ProReducer
