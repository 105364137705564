import { Asignation, CreateKitsAsignation, Dealer, KitsAsignation, StatusAsignations, CreateDeliveryAsignation } from '../../pages/NFI/models/nfi.model';
import { NFIActions } from './constants';
import { User } from '../../pages/account/models/user.model';
import { History } from 'history';

export const loadingCreateAsignation = (asignation: Partial<Asignation>, history: History<any>, formData: {totalKits: any[], dealer: Partial<Dealer>}) => ({
    type: NFIActions.LOADING_CREATE_ASIGNATION,
    payload: {asignation, history, formData},
});

export const successCreateAsignation = (payload: Asignation) => ({
    type: NFIActions.SUCCESS_CREATE_ASIGNATION,
    payload,
});

export const toggleNfiLoading = (payload: boolean = null) => ({
    type: NFIActions.TOOGLE_LOADING_STATUS,
    payload,
});

export const loadingAddNewKitToAsignation = (idAsignation: number, kit: CreateKitsAsignation[]) => ({
    type: NFIActions.LOADING_ADD_NEW_KIT_ASIGNATION,
    payload: {idAsignation, kit},
});

export const successAddNewKitAsignation = (completeInfoKit: KitsAsignation[]) => ({
    type: NFIActions.SUCCESS_ADD_NEW_KIT_ASIGNATION,
    payload:completeInfoKit,
});

export const loadingChangeStatusAsignation = (idAsignation: number, status: StatusAsignations) => ({
    type: NFIActions.LOADING_CHANGE_STATUS_ASIGNATION,
    payload: {idAsignation, status},
});

export const successChangeStatusAsignation = (asignation: Asignation) => ({
    type: NFIActions.SUCCESS_CHANGE_STATUS_ASIGNATION,
    payload: asignation,
});

export const loadingGetAsignations = (payload: {status: string, dealerId?: number, focalPointId?: number, limit: number, page: number}) => ({
    type: NFIActions.LOADING_GET_ASIGNATIONS_LIST,
    payload,
});

export const getAsignations = (payload: Asignation[]) => ({
    type: NFIActions.GET_ASIGNATIONS_LIST,
    payload,
});

export const setDetailAsignations = (payload: Asignation) => ({
    type: NFIActions.SET_DETAIL_ASIGNATIONS,
    payload,
});

export const setAsignationsDelivery = (payload: Asignation) => ({
    type: NFIActions.SET_ASIGNATIONS_DELIVERY,
    payload,
});

export const setDetailRegisterDelivery = (payload: CreateDeliveryAsignation) => {
    return ({
    type: NFIActions.SET_DETAIL_REGISTER_DELIVERY,
    payload,
})};

export const setDetailFormRegisterDelivery = (payload: CreateDeliveryAsignation) => {
    return ({
    type: NFIActions.SET_DETAIL_FORM_REGISTER_DELIVERY,
    payload,
})};

export const savePartialAsignations = (payload: Partial<Asignation>) => ({
    type: NFIActions.SAVE_PRE_ASIGNATIONS,
    payload,
});

export const loadingCreateDeliveryAsignation = (asignation: Partial<CreateDeliveryAsignation>, history: History<any>, formData: any) => ({
    type: NFIActions.LOADING_CREATE_DELIVERY_ASIGNATION,
    payload: {asignation, history, formData},
});


//NFI DEALER
export const loadingGetAllDealers = () => ({
    type: NFIActions.LOADING_GET_LIST_DEALERS,
    payload: null,
});
export const successGetAllDealers = (payload: Dealer[]) => ({
    type: NFIActions.SUCCESS_GET_LIST_DEALER,
    payload,
});
export const setCreateDealerFrom = (payload: string | null) => ({
    type: NFIActions.CREATE_DEALER_FROM,
    payload,
});
export const setAssignPreRegisterDealer = (payload: Partial<Dealer>) => ({
    type: NFIActions.CREATE_PRE_REGISTER_DEALER,
    payload,
});
export const clearAssignPreRegisterDealer = () => ({
    type: NFIActions.CLEAR_PRE_REGISTER_DEALER,
    payload: null,
});
export const loadingCreateDealer = (payload: {dealerInfo: Dealer, userInfo: User, from: string | null, history: History<any>,}) => ({
    type: NFIActions.LOADING_CREATE_DEALER,
    payload,
});

export const successCreateDealer = (payload: Dealer) => ({
    type: NFIActions.SUCCESS_CREATE_DEALER,
    payload,
});

// ENTITIES

export const loadingGetEntities = () => ({
    type: NFIActions.LOADING_GET_ENTITIES_LIST,
    payload: null,
});

export const getEntities = (payload: {id: number, name: string}[]) => ({
    type: NFIActions.GET_ENTITIES_LIST,
    payload,
});