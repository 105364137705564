// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects"
import { FOCALKEYPOINT } from "../../constants/auth"

import {
  login as loginApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  forgotPasswordConfirm,
} from "../../helpers/"

import {
  APICore,
  AUTH_TOKEN,
  EXPIRATION_TIME,
  LAUNCH_EXIT_FLAG,
  setAuthorization,
} from "../../helpers/api/apiCore"
import { apiCreateUser } from "../../helpers/api/auth"
import { starGetAccommodationUser } from "../Accommodation/actions"
import {
  loadingGetListKits,
  starGetDepartments,
  starGetEntities,
  starGetEntitiesType,
  starGetListRelationShips,
  starGetServicesPROList,
  TypeServicePro,
} from "../actions"
import { authApiResponseSuccess, authApiResponseError } from "./actions"
import { AuthActionTypes } from "./constants"

/* Instancia de la clase api para controlar las peticiones */
const api = new APICore()

/**
 * Login the user
 */
function* login({ payload: { username, password } }) {
  try {
    const response = yield call(loginApi, { username, password })
    const user = response.data
    api.setLoggedInUser(user)
    setAuthorization(user.token)
    localStorage.setItem(AUTH_TOKEN, user.token)
    const currentDate = new Date()
    const expireTime = currentDate.getTime() + 20 * 60000
    localStorage.setItem(EXPIRATION_TIME, expireTime)
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user))
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error.response.data.mensaje))
    api.setLoggedInUser(null)
    setAuthorization(null)
  }
}

/**
 * Logout the user
 */
function* LoginSuccessAction({ payload: { actionType, data } }) {
  try {
    if (actionType !== AuthActionTypes.LOGOUT_USER) {
      yield put(starGetAccommodationUser())
      yield put(starGetEntities())
      yield put(starGetDepartments())
      yield put(starGetServicesPROList(TypeServicePro.beneficiaryTypes))
      yield put(starGetServicesPROList(TypeServicePro.disabilities))
      yield put(starGetServicesPROList(TypeServicePro.etpvInformations))
      yield put(starGetServicesPROList(TypeServicePro.etpvOrientations))
      yield put(starGetServicesPROList(TypeServicePro.homeRoles))
      yield put(starGetServicesPROList(TypeServicePro.informationServices))
      yield put(loadingGetListKits())
      yield put(starGetListRelationShips())
      //yield put(starGetServicesPROList(TypeServicePro.kitServicesGiven))
      yield put(starGetServicesPROList(TypeServicePro.migrationProfiles))
      yield put(starGetServicesPROList(TypeServicePro.migrationSituations))
      yield put(starGetServicesPROList(TypeServicePro.orientationServices))
      yield put(starGetServicesPROList(TypeServicePro.pointAssistanceServices))
      yield put(starGetServicesPROList(TypeServicePro.protectionAssistanceServices))
      yield put(starGetServicesPROList(TypeServicePro.referenceServices))
      yield put(starGetServicesPROList(TypeServicePro.vulnerabilities))
      yield put(starGetServicesPROList(TypeServicePro.communicationChanel))
      yield put(starGetServicesPROList(TypeServicePro.providerMode))
      yield put(starGetServicesPROList(TypeServicePro.providerInstitution))
      yield put(starGetServicesPROList(TypeServicePro.providerPointService))
      if (data.profile === "Admin" || data.profile === "PF NFI") {
        yield put(starGetEntitiesType(FOCALKEYPOINT.PF_NFI))
        yield put(starGetEntitiesType(FOCALKEYPOINT.EXTERNAL))
      }
    }
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error))
  }
}

/**
 * Logout the user
 */
function* logout() {
  try {
    api.setLoggedInUser(null)
    setAuthorization(null)
    localStorage.setItem(AUTH_TOKEN, null)
    localStorage.setItem(LAUNCH_EXIT_FLAG, null)
    localStorage.setItem(EXPIRATION_TIME, null)
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}))
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error))
  }
}

function* signup({ payload: { fullname, email, password } }) {
  try {
    const response = yield call(signupApi, { fullname, email, password })
    const user = response.data
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user))
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error))
    api.setLoggedInUser(null)
    setAuthorization(null)
  }
}

export function* createUser({ payload }) {
  try {
    yield call(apiCreateUser, payload)
    return true
  } catch (error) {
    console.error(`error`, error)
    return false
  }
}

function* forgotPassword({ payload: { username } }) {
  try {
    const response = yield call(forgotPasswordApi, { username })
    yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data))
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error))
  }
}

function* forgotPasswordChange({ payload: { data } }) {
  try {
    const response = yield call(forgotPasswordConfirm, data)
    yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data))
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error))
  }
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login)
}

export function* watchLoginSuccess() {
  yield takeEvery(AuthActionTypes.API_RESPONSE_SUCCESS, LoginSuccessAction)
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout)
}

export function* watchSignup() {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup)
}

export function* watchForgotPassword() {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword)
}

export function* watchForgotPasswordChange() {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange)
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchForgotPasswordChange),
    fork(watchLoginSuccess),
  ])
}

export default authSaga
