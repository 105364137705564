export enum NFIActions {
    LOADING_GET_ASIGNATIONS_LIST = "@@nfi/LOADING_GET_ASIGNATIONS_LIST",
    GET_ASIGNATIONS_LIST = "@@nfi/GET_ASIGNATIONS_LIST",
    LOADING_CREATE_ASIGNATION = "@@nfi/LOADING_CREATE_ASIGNATION",
    SUCCESS_CREATE_ASIGNATION = "@@nfi/SUCCESS_CREATE_ASIGNATION",
    LOADING_ADD_NEW_KIT_ASIGNATION = "@@nfi/LOADING_ADD_NEW_KIT_ASIGNATION",
    SUCCESS_ADD_NEW_KIT_ASIGNATION = "@@nfi/SUCCESS_ADD_NEW_KIT_ASIGNATION",
    LOADING_CHANGE_STATUS_ASIGNATION = "@@nfi/LOADING_CHANGE_STATUS_ASIGNATION",
    SUCCESS_CHANGE_STATUS_ASIGNATION = "@@nfi/SUCCESS_CHANGE_STATUS_ASIGNATION",
    LOADING_CREATE_DELIVERY_ASIGNATION = "@@nfi/LOADING_CREATE_DELIVERY_ASIGNATION",

    SET_ASIGNATIONS_DELIVERY = "@@nfi/SET_ASIGNATIONS_DELIVERY",
    SET_DETAIL_REGISTER_DELIVERY = "@@nfi/SET_DETAIL_REGISTER_DELIVERY",
    SET_DETAIL_FORM_REGISTER_DELIVERY = "@@nfi/SET_DETAIL_FORM_REGISTER_DELIVERY",
    SET_DETAIL_ASIGNATIONS = "@@nfi/SET_DETAIL_ASIGNATIONS",
    SAVE_PRE_ASIGNATIONS = "@@nfi/SAVE_PRE_ASIGNATIONS",

    LOADING_GET_ENTITIES_LIST = "@@nfi/LOADING_GET_ENTITIES_LIST",
    GET_ENTITIES_LIST = "@@nfi/GET_ENTITIES_LIST",

    LOADING_GET_LIST_DEALERS = "@@nfi/LOADING_GET_LIST_DEALERS",
    SUCCESS_GET_LIST_DEALER = "@@nfi/SUCCESS_GET_LIST_DEALER",
    CREATE_DEALER_FROM = "@@nfi/CREATE_DEALER_FROM",
    CREATE_PRE_REGISTER_DEALER = "@@nfi/CREATE_PRE_REGISTER_DEALER",
    CLEAR_PRE_REGISTER_DEALER = "@@nfi/CLEAR_PRE_REGISTER_DEALER",
    LOADING_CREATE_DEALER = "@@nfi/LOADING_CREATE_DEALER",
    SUCCESS_CREATE_DEALER = "@@nfi/SUCCESS_CREATE_DEALER",

    TOOGLE_LOADING_STATUS = "@@nfi/TOOGLE_LOADING_STATUS",
}