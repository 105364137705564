import { accommodationTypes } from "./constants"

type AccommodatioAction = { type: string; payload: any }

export const actAccommodationInfo: (data: any) => AccommodatioAction = (data) => ({
  type: accommodationTypes.ACCOMMODATION_INFO,
  payload: data,
})

export const actClearAccommodationInfo: () => AccommodatioAction = () => ({
  type: accommodationTypes.ACCOMMODATION_CLEAR_INFO,
  payload: null,
})

export const setAccommodationIngress: (data: any) => AccommodatioAction = (data) => ({
  type: accommodationTypes.ACCOMMODATION_INGRESS_FAMILY,
  payload: data,
})

export const setAccommodationType: (type: number) => AccommodatioAction = (type) => ({
  type: accommodationTypes.ACCOMMODATION_TYPE,
  payload: type,
})

export const storeAccommodationType: (type: number) => AccommodatioAction = (type) => ({
  type: accommodationTypes.SET_ACCOMMODATION_TYPE,
  payload: type,
})

export const setDetailAccommodationMigrantType: (type: any[]) => AccommodatioAction = (type) => ({
  type: accommodationTypes.ACCOMMODATION_DETAIL_MIGRANT_INFO,
  payload: type,
})

export const setDetailAccommodationMigrantClearType: () => AccommodatioAction = () => ({
  type: accommodationTypes.ACCOMMODATION_DETAIL_MIGRANT_INFO_CLEAR,
  payload: null,
})

export const actAddPersonIngress: (data: any) => AccommodatioAction = (data) => ({
  type: accommodationTypes.ACCOMMODATION_ADD_INGRESS_PERSON,
  payload: data,
})

export const removePersonOfIngress: (id: any) => AccommodatioAction = (id) => ({
  type: accommodationTypes.ACCOMMODATION_REMOVE_INGRESS_PERSON,
  payload: { id },
})

export const actClearIngress: () => AccommodatioAction = () => ({
  type: accommodationTypes.ACCOMMODATION_CLEAR_INGRESS,
  payload: null,
})

export const actAddPersonAdditionalIngress: (data: any) => AccommodatioAction = (data) => ({
  type: accommodationTypes.ACCOMMODATION_ADD_ADDITIONAL_PERSON,
  payload: data,
})

export const removePersonOfAdditionalIngress: (id: any) => AccommodatioAction = (id) => ({
  type: accommodationTypes.ACCOMMODATION_REMOVE_ADDITIONAL_PERSON,
  payload: { id },
})

export const actClearAdditionalIngress: () => AccommodatioAction = () => ({
  type: accommodationTypes.ACCOMMODATION_CLEAR_ADDITIONAL_INGRESS,
  payload: null,
})

// Selected users
export const setSelectedAccommodationPersonType: (type: any[]) => AccommodatioAction = (type) => ({
  type: accommodationTypes.ACCOMMODATION_SELECTION_EXIT_PEOPLE,
  payload: type,
})

export const clearSelectedAccommodationPersonType: () => AccommodatioAction = () => ({
  type: accommodationTypes.ACCOMMODATION_CLEAR_SELECTION_EXIT_PEOPLE,
  payload: null,
})

export const starGetAccommodationUser: () => AccommodatioAction = () => ({
  type: accommodationTypes.START_GET_ACCOMMODATION_USER,
  payload: null,
})

export const actSetAccommodationUser: (data: any) => AccommodatioAction = (data: any) => ({
  type: accommodationTypes.SET_ACCOMMODATION_USER,
  payload: data,
})

export const starGetAccommodationAditionalServicesUser: (data: {closed:boolean, limit:number, page:number}) => AccommodatioAction = (data) => ({
  type: accommodationTypes.START_GET_MIGRANT_ACCOMMODATION_ADITIONAL_SERVICES_USER,
  payload: data,
})

export const actSetAccommodationAditionalServicesUser: (data: any) => AccommodatioAction = (data: any) => ({
  type: accommodationTypes.SET_ACCOMMODATION_ADITIONAL_SERVICES_USER,
  payload: data,
})


export const actAccommodationAditionalServicesData: (data) => AccommodatioAction = (data) => ({
  type: accommodationTypes.GET_MIGRANT_ACCOMMODATION_ADITIONAL_SERVICES_DATA,
  payload: data,
})


