import { TransportTypes } from "./constants"

type TransportAction = { type: string; payload: any }

export const actPeopleTransport: (data: any) => TransportAction = (data) => ({
  type: TransportTypes.SET_LIST_TRANSPORT,
  payload: data,
})

export const actRemovePeoplebyId: (id: any) => TransportAction = (id) => ({
  type: TransportTypes.REMOVE_PERSON_OF_LIST_TRANSPORT,
  payload: IdleDeadline,
})

export const actSetTransportRequestType = (data: any, onOK: any) => async (dispatch: any) => {
  dispatch({
    type: TransportTypes.SET_TYPE_REGISTRIES_TRANSPORT,
    payload: data,
  })
  onOK && onOK()
}

export const actSetTransportRequest = (data: any, onOK: any) => async (dispatch: any) => {
  dispatch({
    type: TransportTypes.SET_IFO_REQUEST_TRANSPORT,
    payload: data,
  })
  onOK && onOK()
}

export const actClearListPeopleTransport: () => TransportAction = () => ({
  type: TransportTypes.CLEAR_LIST_TRANSPORT_STATE,
  payload: null,
})
