import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";

import { Provider } from "react-redux";
import Store from './redux/store'
import LoaderSpinner from "./components/LoaderSpinner";

ReactDOM.render(
  <Provider store={Store.store}>
    <App />
    <LoaderSpinner />
    <ToastContainer />
  </Provider>,
  document.getElementById("root")
);
