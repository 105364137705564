import { GroupTypeVulnerations } from "../pages/persons/models/migrants.model"

export const calcularEdad = (FechaNacimiento) => {
  const fechaNace = new Date(FechaNacimiento)
  const fechaActual = new Date()

  const mes = fechaActual.getMonth()
  const dia = fechaActual.getDate()
  const año = fechaActual.getFullYear()
  const mesNace = fechaNace.getMonth()
  const diaNace = fechaNace.getDate()
  const añoNace = fechaNace.getFullYear()


  fechaActual.setDate(dia)
  fechaActual.setMonth(mes)

  if (mes > mesNace && año > añoNace) fechaActual.setFullYear(año)
  else if (mes === mesNace && dia >= (diaNace + 1) && año > añoNace) fechaActual.setFullYear(año)
  else fechaActual.setFullYear(año - 1)

  const edad = fechaActual.getFullYear() - fechaNace.getFullYear()
  return edad
}

export const translateStatusAccommodationRequest = (status) => {
  switch (status) {
    case "PENDING":
      return "Pendiente"
    case "REJECTED":
      return "Rechazado"
    case "ACCEPTED":
      return "Gestionado"
    default:
      return "Pendiente"
  }
}

export const translateStatusAccommodation = (status) => {
  switch (status) {
    case "ACTIVE":
      return "Activo"
    case "INACTIVE":
      return "Inactivo"
    default:
      return "Sin estado"
  }
}


export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const setRulesAgeAndSex = (edad, typeSex) => {
  let keys = [GroupTypeVulnerations.ALL];
  if (edad >= 0 && edad <= 3) keys.push(GroupTypeVulnerations.BABY)
  if (typeSex) {
    switch (typeSex) {
      case 1:
        if (edad > 3 && edad < 11) keys = [...keys, GroupTypeVulnerations.GIRL, GroupTypeVulnerations.CHILD]
        else if (edad >= 11 && edad <= 18) keys = [...keys,GroupTypeVulnerations.TEENAGE_GIRL, GroupTypeVulnerations.TEENAGE]
        else if (edad >= 18 && edad < 60) keys = [...keys, GroupTypeVulnerations.WOMAN, GroupTypeVulnerations.ADULT]
        else keys = [...keys, GroupTypeVulnerations.GRANDPARENT_WOMAN, GroupTypeVulnerations.GRANDPARENT]
        break;
      case 2:
        if (edad > 3 && edad < 11) keys = [...keys, GroupTypeVulnerations.BOY, GroupTypeVulnerations.CHILD]
        else if (edad >= 11 && edad <= 18) keys = [...keys,GroupTypeVulnerations.TEENAGE_BOY, GroupTypeVulnerations.TEENAGE]
        else if (edad >= 18 && edad < 60) keys = [...keys, GroupTypeVulnerations.MAN, GroupTypeVulnerations.ADULT]
        else keys = [...keys, GroupTypeVulnerations.GRANDPARENT_MAN, GroupTypeVulnerations.GRANDPARENT]
        break;
      default:
        if (edad > 3 && edad < 11) keys = [...keys, GroupTypeVulnerations.CHILD, GroupTypeVulnerations.OTHER]
        else if (edad >= 11 && edad <= 18) keys = [...keys, GroupTypeVulnerations.TEENAGE, GroupTypeVulnerations.OTHER]
        else if (edad >= 18 && edad < 60) keys = [...keys, GroupTypeVulnerations.ADULT, GroupTypeVulnerations.OTHER]
        else keys = [...keys, GroupTypeVulnerations.GRANDPARENT, GroupTypeVulnerations.OTHER]
        break;
      }
    } else {
      if (edad > 3 && edad < 11) keys = [...keys, GroupTypeVulnerations.CHILD, GroupTypeVulnerations.OTHER]
      else if (edad >= 11 && edad <= 18) keys = [...keys, GroupTypeVulnerations.TEENAGE, GroupTypeVulnerations.OTHER]
      else if (edad >= 18 && edad < 60) keys = [...keys, GroupTypeVulnerations.ADULT, GroupTypeVulnerations.OTHER]
      else keys = [...keys, GroupTypeVulnerations.GRANDPARENT, GroupTypeVulnerations.OTHER]
    }

  return keys
}
