/**
 * Renders the preloader
 */
import Loader from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";

const LoaderSpinner = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div className="spinner">
        <Loader type="ThreeDots" color="#5B92E5" height="100" width="100" />
      </div>
    )
  );
};

export default LoaderSpinner;
