import axios from "axios"
import MySwal from "../../components/SwetAlert"
const PUBLIC_REQUEST_KEY = "public-request"
const AUTH_TOKEN = "auth_token"
const TOKEN_PAYLOAD_KEY = "Authorization"
const AUTH_SESSION_KEY = "oim-user"
const EXPIRATION_TIME = "expiration_time"
const LAUNCH_EXIT_FLAG = null
const baseApiurlUsersAuth = process.env.REACT_APP_USER_URL

const useInterceptor = () => {
  const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token
    else delete axios.defaults.headers.common["Authorization"]
  }

  const getUserFromSession = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY)
    return user ? (typeof user == "object" ? user : JSON.parse(user)) : null
  }

  const user = getUserFromSession()
  if (user) {
    const { token } = user
    if (token) {
      setAuthorization(token)
    }
  }

  const handleRequestSuccess = (request) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN)
    const loggedInUser = getUserFromSession()
    const currentTime = new Date()
    const expirationTime = localStorage.getItem(EXPIRATION_TIME)

    if (!jwtToken) {
      request.headers[TOKEN_PAYLOAD_KEY] = `Bearer 1`
    } else if (jwtToken && !request.headers[PUBLIC_REQUEST_KEY]) {
      const department =
        loggedInUser?.departments?.length > 0
          ? loggedInUser?.departments
          : loggedInUser?.regionalLink?.length > 0
          ? loggedInUser?.regionalLink
          : [0]
      request.headers["Content-Type"] = "application/json"
      request.headers["accept"] = "application/json"
      request.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
      request.headers["profile"] = `${loggedInUser?.profile}`
      request.headers["roles"] = `${loggedInUser?.roles}`
      request.headers["entityId"] = `${loggedInUser?.entityId}`
      request.headers["focalPointId"] = `${loggedInUser?.focalPointId}`
      request.headers["username"] = `${loggedInUser?.username}`
      request.headers["departments"] = `${department}`
      validateExpiredSesionRefreshToken(expirationTime, currentTime, jwtToken)
    }
    validateExpiredSesionToken(request, jwtToken)

    return request
  }

  const handleRequestError = (error) => {
    console.error(`REQUEST ERROR! => ${error}`)
  }

  const handleResponseSuccess = (response) => {
    return response
  }

  const handleResponseError = (error) => {
    console.error(`RESPONSE ERROR! => ${error}`)
    return Promise.reject(error)
  }

  const validateExpiredSesionRefreshToken = (expirationTime, currentTime, jwtToken) => {
    if (Number(expirationTime) <= currentTime.getTime()) {
      const configHeaders = {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
      fetch(`${baseApiurlUsersAuth}/token/refresh`, configHeaders)
        .then((resp) => {
          if (resp.status !== 401) {
            return resp.json()
          } else {
            MySwal.fire({
              icon: "warning",
              title: <p>Su sesión ha expirado por inactividad.</p>,
              confirmButtonText: "Volver al login",
              closeOnClickOutside: false,
              showCancelButton: false,
              allowEscapeKey: false,
            }).then(() => {
              window.location.href = "/account/logout"
              throw new axios.Cancel("Operation canceled by the user.")
            })
            localStorage.setItem(LAUNCH_EXIT_FLAG, "TRUE")
            localStorage.setItem(AUTH_TOKEN, null)
            localStorage.setItem(EXPIRATION_TIME, null)
            setAuthorization(null)
          }
        })
        .then((data) => {
          if (data) {
            const expireTime = currentTime.getTime() + 20 * 60000
            localStorage.setItem(AUTH_TOKEN, data.token)
            localStorage.setItem(EXPIRATION_TIME, expireTime)
            setAuthorization(data.token)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const validateExpiredSesionToken = (request, jwtToken) => {
    if (!jwtToken && !request.headers[PUBLIC_REQUEST_KEY]) {
      if (localStorage.getItem(LAUNCH_EXIT_FLAG) === null) {
        MySwal.fire({
          icon: "warning",
          title: <p>Su sesión ha expirado por inactividad</p>,
          confirmButtonText: "Volver al login",
          showCancelButton: false,
          closeOnClickOutside: false,
          allowEscapeKey: false,
        }).then(() => {
          window.location.href = "/account/logout"
        })
      }
      localStorage.setItem(LAUNCH_EXIT_FLAG, "TRUE")
      throw new axios.Cancel("Operation canceled by the user.")
    }
  }

  axios.defaults.params = {}
  axios.interceptors.request.use(handleRequestSuccess, handleRequestError)
  axios.interceptors.response.use(handleResponseSuccess, handleResponseError)
}

export default useInterceptor
