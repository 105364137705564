import { Button } from "react-bootstrap"
import classNames from "classnames"
import React from "react"

type ButtonCustomProps = {
  children: any
  variant: string
  onClick?: () => void
  startIcon?: any
  className?: string
  endIcon?: any
  size?: "sm" | "lg"
  type?: string
  disabled?: boolean
  rest?: any
  ref?: React.MutableRefObject<any>
  style?: any
}

const ButtonCustom = React.forwardRef((props: ButtonCustomProps, ref) => {
  return (
    <Button
      style={props.style}
      ref={ref}
      variant={props.variant}
      className={classNames(`d-flex align-items-center`, props.className)}
      onClick={props.onClick}
      size={props.size}
      type={props.type}
      disabled={props.disabled}
      {...props.rest}
    >
      {props.startIcon && props.startIcon}
      {props.children}
      {props.endIcon && props.endIcon}
    </Button>
  )
})

export default ButtonCustom
