import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { useTransport } from "../pages/transport/hooks"

export const useGenerlsForms = () => {
  const history = useHistory()
  const { person } = useSelector((state) => state.Beneficiario)
  const { type, ingress, additionalIngress } = useSelector((state) => state.Accomodation)
  const { people } = useSelector((state) => state.transport)
  const { createBasicRegistry } = useTransport()

  const validateRoute = () => {
    switch (type) {
      case 1:
        return { label: "Registro alojamiento", path: "/accommodation/request/create" }
      case 5:
        return { label: "Registro NFI", path: "/nfi/service/create" }
      case 6:
        return { label: "Solicitar transporte", path: "/transport/request" }
      default:
        return { label: "Registro servicios PRO", path: "/services-pro/search" }
    }
  }

  const validateData = () => {
    switch (type) {
      case 1:
        return ingress
      case 3:
        return additionalIngress
      case 6:
        return people
      default:
        return person.family
    }
  }

  const validateActionForm = () => {
    switch (type) {
      case 1:
        return ingress.length
      case 3:
        return additionalIngress.length
      case 6:
        return people.length
      default:
        return person.family.length
    }
  }

  const handleGotoForm = () => {
    switch (type) {
      case 1:
        history.push("/accommodation/family/ingress")
        break
      case 3:
        history.push("/accommodation/family/ingress/additional")
        break
      case 5:
        history.push("/nfi/service/create")
        break
      case 6:
        createBasicRegistry(people)
        break
      default:
        history.push("/services-pro/create")
        break
    }
  }

  return {
    validateRoute,
    validateData,
    handleGotoForm,
    validateActionForm,
  }
}
