// @flow
import { all, call, fork, takeLatest, put } from "redux-saga/effects"
import { UtilityTypes } from "./constants"
import {
  apiCallgenders,
  apiCallGendersIdentity,
  apiCallIdentityTypes,
  apiCallNationalities,
  apiCallEthnicities,
  apiCallCountries,
  apiCallCities,
  apiCallDepartmentsByCountryCode,
  apiCallInstitutes,
  apiCallCitiesByDepartment,
  apiCallServicesPro,
  apiCallReasonsTransport,
  apiCallTypeTransport,
  apiCallRelationShips,
} from "../../helpers/api/utils"
import { Genders } from "../../interfaces/Gender"
import {
  succesListGenders,
  succesListIdentityTypes,
  succesListNationalitys,
  succesListEthnicities,
  succesListContries,
  succesListDepartments,
  succesListCities,
  succesListInstitutes,
  succesListGendersIdentity,
  succesListReasonsTransport,
  succesListTypeTransport,
  TypeServicePro,
  succesGetServicesPROList,
  successGetEntitiesType,
  successGetListKits,
  successGetEntities,
  successGetListRelationShips,
} from "./actions"
import { IdentityTypes } from "../../interfaces/IdentityTypes"
import { Nationality } from "../../interfaces/Nationality"
import { Ethnicities } from "../../interfaces/Ethnicities"
import { takeEvery } from "@redux-saga/core/effects"
import { apiUserEntities } from "../../helpers/api/auth"
import { apiCallKitsList } from "../../helpers/NFI"
import { getListEntites } from "../../helpers/Users"


function* getAllGenders() {
  try {
    const response: Genders[] = yield call(apiCallgenders)
    yield put(succesListGenders(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllGendersIdentity() {
  try {
    const response: Genders[] = yield call(apiCallGendersIdentity)
    yield put(succesListGendersIdentity(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllIdentityTypes() {
  try {
    const response: IdentityTypes[] = yield call(apiCallIdentityTypes)
    yield put(succesListIdentityTypes(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllNationalities() {
  try {
    const response: Nationality[] = yield call(apiCallNationalities)
    yield put(succesListNationalitys(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllEthnicities() {
  try {
    const response: Ethnicities[] = yield call(apiCallEthnicities)
    yield put(succesListEthnicities(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllCountries() {
  try {
    const response: any[] = yield call(apiCallCountries)
    yield put(succesListContries(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}
function* getCitiesByDepartment(data: { payload: string }) {
  try {
    const response: any[] = yield call(apiCallCitiesByDepartment, data.payload)
    yield put(succesListCities(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}
function* getAllCtities() {
  try {
    const response: any[] = yield call(apiCallCities)
    yield put(succesListCities(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getDepartments(action: { payload: string | null }) {
  try {
    let response: any[]
    if (!action.payload) {
      response = yield call(apiCallDepartmentsByCountryCode, 64)
    } else {
      response = yield call(apiCallDepartmentsByCountryCode, action.payload)
    }
    yield put(succesListDepartments(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllInstitutes() {
  try {
    const response: any[] = yield call(apiCallInstitutes)
    yield put(succesListInstitutes(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllServicesPro(action: { payload: TypeServicePro }) {
  try {
    const response = yield call(apiCallServicesPro, action.payload)
    yield put(succesGetServicesPROList(action.payload, response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}
function* getAllReasonsTransport() {
  try {
    const response: any[] = yield call(apiCallReasonsTransport)
    yield put(succesListReasonsTransport(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllTypeTransport() {
  try {
    const response: any[] = yield call(apiCallTypeTransport)
    yield put(succesListTypeTransport(response))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllEntitiesByType(action) {
  try {
    const response = yield call(apiUserEntities, action.payload)
    yield put(successGetEntitiesType(response.data, action.payload))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

function* getAllEntities() {
  try {
    const { data } = yield call(getListEntites)
    yield put(successGetEntities(data))
  } catch (error) {
    console.error("Se presentó un error")
  }
}

/**
 * Watchers
 */
export function* watchCallListGenders(): any {
  yield takeLatest(UtilityTypes.START_LIST_GENDERS, getAllGenders)
}

export function* watchCallListGendersIdentity(): any {
  yield takeLatest(UtilityTypes.START_LIST_GENDERS_IDENTITIES, getAllGendersIdentity)
}

export function* watchCallListIdentityTypes(): any {
  yield takeLatest(UtilityTypes.START_IDENTITYTYPES, getAllIdentityTypes)
}

export function* watchCallNationalities(): any {
  yield takeLatest(UtilityTypes.START_NATIONALITIES, getAllNationalities)
}

export function* watchCallEthnicities(): any {
  yield takeLatest(UtilityTypes.START_ETHNICITIES, getAllEthnicities)
}

export function* watchCallCountries(): any {
  yield takeLatest(UtilityTypes.START_COUNTRIES, getAllCountries)
}

export function* watchCallCities(): any {
  yield takeLatest(UtilityTypes.START_CITIES, getAllCtities)
}
export function* watchCallCitiesByDepartment(): any {
  yield takeLatest(UtilityTypes.START_CITIES_BY_DEPARTMENT, getCitiesByDepartment)
}

export function* watchCallDepartments(): any {
  yield takeLatest(UtilityTypes.START_DEPARTMENTS, getDepartments)
}

export function* watchCallInstitutes(): any {
  yield takeLatest(UtilityTypes.START_INSTITUTES, getAllInstitutes)
}

export function* watchCallService(): any {
  yield takeEvery(UtilityTypes.START_GET_SERVICE_PRO_DATA_SETS, getAllServicesPro)
}
export function* watchCallReasonsTransport(): any {
  yield takeLatest(UtilityTypes.START_REASONS_TRANSPORT, getAllReasonsTransport)
}

export function* watchCallTypeTransport(): any {
  yield takeLatest(UtilityTypes.START_TYPE_TRANSPORT, getAllTypeTransport)
}

export function* watcherListEntitiesByType() {
  yield takeEvery(UtilityTypes.START_GET_ENTITIES_BY_TYPE, getAllEntitiesByType)
}

export function* watcherListEntities() {
  yield takeEvery(UtilityTypes.START_ENTITIES, getAllEntities)
}

function* watcherLoadingGetListKitStatusAsignations() {
  yield takeEvery(UtilityTypes.LOADING_GET_KITS_LIST, function* () {
    const data = yield call(apiCallKitsList)
    yield put(successGetListKits(data))
  })
}

function* watcherLoadingGetListRelationShips() {
  yield takeEvery(UtilityTypes.START_RELATIONSHIPS, function* () {
    const data = yield call(apiCallRelationShips)
    yield put(successGetListRelationShips(data))
  })
}

function* UtilitySaga(): any {
  yield all([
    fork(watchCallListGenders),
    fork(watchCallListGendersIdentity),
    fork(watchCallListIdentityTypes),
    fork(watchCallNationalities),
    fork(watchCallEthnicities),
    fork(watchCallCountries),
    fork(watchCallCities),
    fork(watchCallCitiesByDepartment),
    fork(watchCallDepartments),
    fork(watchCallInstitutes),
    fork(watchCallService),
    fork(watchCallReasonsTransport),
    fork(watchCallTypeTransport),
    fork(watcherListEntitiesByType),
    fork(watcherLoadingGetListKitStatusAsignations),
    fork(watcherListEntities),
    fork(watcherLoadingGetListRelationShips),
  ])
}

export default UtilitySaga
