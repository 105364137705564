import { APICore } from "../api/apiCore"
//Variables de configuracion

const api = new APICore()
const baseApiurl = process.env.REACT_APP_ACCOMMODATION_URL

const getAccomodationsList = (params = "") => {
  const baseUrl = baseApiurl + "/accomodations"
  if (!params) return api.get(`${baseUrl}`)
  else return api.get(`${baseUrl}`, params)
}

const getAccomodationsAditionalServicesList = (params) => {
  params = { ...params, page: params?.page ?? 1, limit: params?.limit ?? 5}
  const baseUrl = baseApiurl + `/migrants`
  if (!params) return api.get(`${baseUrl}`)
 else return api.get(`${baseUrl}`, params)
}

const getAccomodationsListRequest = (params = null) => {
  params = { ...params, page: params?.page ?? 1, limit: params?.limit ?? 5 }
  const baseUrl = baseApiurl + "/requests"
  if (!params) return api.get(`${baseUrl}`)
  else return api.get(`${baseUrl}`, params)
}

const getAccomodationRequestById = async (id) => {
  const baseUrl = baseApiurl + `/requests?accomodationId=${id}`
  try {
    return api.get(`${baseUrl}`)
  } catch (error) {
    console.error(error)
  }
}

const postAccommodationRequest = (request) => {
  const baseUrl = baseApiurl + `/requests`
  try {
    return api.create(`${baseUrl}`, request)
  } catch (error) {
    console.error(error)
  }
}

const patchRejectAccommodationRequest = async (data, id) => {
  const baseUrl = baseApiurl + `/requests/${id}`
  try {
    return api.updatePatch(`${baseUrl}`, data)
  } catch (error) {
    console.error(error)
  }
}

const postRegisterAccommodation = async (data) => {
  const baseUrl = baseApiurl + `/registries`
  try {
    return api.create(`${baseUrl}`, data)
  } catch (error) {
    console.error(error)
  }
}

const patchRegisterAccommodation = async (id, params, profile) => {
  const baseUrl = baseApiurl + `/registries/${id}`
  try {
    return api.updatePatch(`${baseUrl}`, params, {
      headers: {
        profile,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

const postCreateModuleAccommodation = async (data, accomodationId) => {
  const baseUrl = `${baseApiurl}/registries/${accomodationId}/modules`
  try {
    return api.create(`${baseUrl}`, data)
  } catch (error) {
    console.error(error)
  }
}
const postRegisterAdditionalServices = async (data, accomodationId) => {
  const baseUrl = `${baseApiurl}/migrants`
  try {
    return api.create(`${baseUrl}`, data)
  } catch (error) {
    console.error(error)
  }
}

const patchAcceptAccommodationRequest = async (data, id) => {
  const baseUrl = baseApiurl + `/requests/${id}`
  try {
    return api.updatePatch(`${baseUrl}`, data)
  } catch (error) {
    console.error(error)
  }
}

const getListRegisterAccomodations = (params) => {
  params = { ...params, page: params?.page ?? 1, limit: params?.limit ?? 5 }
  const baseUrl = baseApiurl + "/registries"
  if (!params) return api.get(`${baseUrl}`)
  return api.get(`${baseUrl}`, params)
}

const getAccommodationStatistics = (id) => {
  const baseUrl = baseApiurl + `/statistics/${id}`
  return api.get(`${baseUrl}`)
}

const postAaddModuleAccommodationRegister = async (data, id) => {
  const baseUrl = baseApiurl + `/registries/${id}/modules`
  try {
    return api.create(`${baseUrl}`, data)
  } catch (error) {
    console.error(error)
  }
}

const createAccomodation = (data) => {
  const baseUrl = baseApiurl + `/accomodations`
  return api.create(baseUrl, data)
}

const updateAccomodation = (id, data) => {
  const baseUrl = baseApiurl + `/accomodations/${id}`
  return api.update(baseUrl, data)
}

const getAccomodationByEmail = (email) => {
  const baseUrl = baseApiurl + `/accomodations/by-email?email=${email}`
  return api.get(baseUrl)
}

const getAccomodationKpi = (filters, params) => {
  const baseUrl = baseApiurl + `/kpi/${filters}`
  return api.get(baseUrl, params)
}

const updateAccomodationsStatus = (username, params) => {
  const baseUrl = baseApiurl + `/accomodations/${username}/active`
  return api.updatePatch(baseUrl, params)
}

export {
  getAccomodationsList,
  getAccomodationsListRequest,
  getAccomodationRequestById,
  postAccommodationRequest,
  patchRejectAccommodationRequest,
  postRegisterAccommodation,
  postCreateModuleAccommodation,
  patchAcceptAccommodationRequest,
  getListRegisterAccomodations,
  getAccommodationStatistics,
  getAccomodationsAditionalServicesList,
  postAaddModuleAccommodationRegister,
  createAccomodation,
  updateAccomodation,
  getAccomodationByEmail,
  getAccomodationKpi,
  patchRegisterAccommodation,
  updateAccomodationsStatus,
  postRegisterAdditionalServices,
}
