// @flow
import { all, call, fork, takeLatest, put } from "redux-saga/effects";
import { personTypes } from "./constants";
import { getPersonById } from "../../helpers/Persons";
import { personInfoSuccess } from "./actions";


/**
 * Find person by id
 * @param {*} payload - id person
 */
function* getPerson({ payload: { id } }) {
  try {
    const response = yield call(getPersonById, { id });
    yield put(personInfoSuccess(response));
  } catch (error) {
    console.error('Se presentó un error consultando la información');
  }
}

/**
 * Watchers
 */
export function* watchCallPersonInfo() {
  yield takeLatest(personTypes.PERSON_INFO, getPerson);
}

function* personSaga() {
  yield all([fork(watchCallPersonInfo)]);
}

export default personSaga;
