// @flow
import { createStore, compose, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import reducers from "./reducers"
import rootSaga from "./sagas"
import { persistStore } from "redux-persist"
import thunk from "redux-thunk"

const initialState = {}


const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, thunk]


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
)
sagaMiddleware.run(rootSaga)
const persistor = persistStore(store)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  store,
  persistor,
}
