// @flow
import React, { Suspense, useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Container } from "react-bootstrap"

// actions
import { changeSidebarType } from "../redux/actions"

// constants
import * as layoutConstants from "../constants/layout"

// components
import ThemeCustomizer from "../components/ThemeCustomizer"

// utils
import { changeBodyAttribute } from "../utils"

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import("./Topbar"))
const LeftSidebar = React.lazy(() => import("./LeftSidebar"))
const Footer = React.lazy(() => import("./Footer"))
const RightSidebar = React.lazy(() => import("./RightSidebar"))

const loading = () => <div className=""></div>

type VerticalLayoutProps = {
  children?: any,
}

type VerticalLayoutState = {
  isMenuOpened?: boolean,
}

const VerticalLayout = (
  { children }: VerticalLayoutProps,
  state: VerticalLayoutState
): React$Element<any> => {
  const dispatch = useDispatch()

  const { leftSideBarTheme, leftSideBarType, layoutWidth, showRightSidebar } = useSelector(
    (state) => ({
      layoutWidth: state.Layout.layoutWidth,
      leftSideBarTheme: state.Layout.leftSideBarTheme,
      leftSideBarType: state.Layout.leftSideBarType,
      showRightSidebar: state.Layout.showRightSidebar,
    })
  )

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  /*
   * layout defaults
   */
  useEffect(() => {
    changeBodyAttribute("data-layout", layoutConstants.LAYOUT_VERTICAL)
  }, [])

  useEffect(() => {
    changeBodyAttribute("data-layout-mode", layoutWidth)
  }, [layoutWidth])

  useEffect(() => {
    changeBodyAttribute("data-leftbar-theme", leftSideBarTheme)
  }, [leftSideBarTheme])

  useEffect(() => {
    changeBodyAttribute("data-leftbar-fix-mode", leftSideBarType)
  }, [leftSideBarType])

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened((prevState) => {
      setIsMenuOpened(!prevState)
    })

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove("sidebar-enable")
      } else {
        document.body.classList.add("sidebar-enable")
      }
    }
  }

  const updateDimensions = useCallback(() => {
    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (window.innerWidth < 768) {
      dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED))
    }
  }, [dispatch])

  useEffect(() => {
    window.addEventListener("resize", updateDimensions)

    return () => {
      window.removeEventListener("resize", updateDimensions)
    }
  }, [dispatch, updateDimensions])

  const isCondensed = leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED
  const isLight = leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_LIGHT

  return (
    <>
      <div className="wrapper">
        <Suspense fallback={loading()}>
          <LeftSidebar isCondensed={isCondensed} isLight={isLight} hideUserProfile={true} />
        </Suspense>
        <div className="content-page">
          <div className="content">
            <Suspense fallback={loading()}>
              <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
            </Suspense>
            <Container fluid>
              <Suspense fallback={loading()}>{children}</Suspense>
            </Container>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>

      {showRightSidebar && (
        <Suspense fallback={loading()}>
          <RightSidebar>
            <ThemeCustomizer />
          </RightSidebar>
        </Suspense>
      )}
    </>
  )
}
export default VerticalLayout
