// @flow
import { personTypes } from "./constants"

type PersonAction = { type: string; payload: any }

export const getPersonInfoById: (id: number) => PersonAction = (id) => ({
  type: personTypes.PERSON_INFO,
  payload: { id },
})

export const personInfoSuccess = (data: any): PersonAction =>
  data({
    type: personTypes.PERSON_INFO_SUCCES,
    payload: data,
  })

export const addPersonToFamily: (data: any) => PersonAction = (data) => ({
  type: personTypes.PERSON_ADD_TO_FAMILY,
  payload: data,
})

export const removePersonOfList: (id: any) => PersonAction = (id) => ({
  type: personTypes.REMOVE_PERSON_OF_LIST,
  payload: { id },
})

export const removeAllPeopleOfList: () => PersonAction = () => ({
  type: personTypes.REMOVE_ALL_PEOPLE_OF_LIST,
  payload: null,
})

export const getFamilyById: (id: number) => PersonAction = (id) => ({
  type: personTypes.PERSON_FAMILY_LIST,
  payload: { id },
})

export const personFinishService: () => PersonAction = () => ({
  type: personTypes.PERSON_FINISH_SERVICE,
  payload: null,
})

export const addPersonToHome: (data: any) => PersonAction = (data) => ({
  type: personTypes.PERSON_CREATE_FAMILY,
  payload: data,
})

export const removePersonToHome: (data: any) => PersonAction = (data) => ({
  type: personTypes.PERSON_REMOVE_FAMILY,
  payload: data,
})

export const removeAllPeopleToHome: () => PersonAction = () => ({
  type: personTypes.PEOPLE_REMOVE_ALL_FAMILY,
  payload: null,
})


export const clearPersonFamily: () => PersonAction = () => ({
  type: personTypes.CLEAR_PERSON_FAMILY,
  payload: null,
})

export const finishCreateHome: () => PersonAction = () => ({
  type: personTypes.PERSON_FINISH_CREATE_FAMILY,
  payload: null,
})

// SEARCH PERSON NOT FOUND
export const setDataPersonNotFound: (data: any) => PersonAction = (data) => ({
  type: personTypes.PERSON_INFO_NOT_FOUND_REGISTER,
  payload: data,
})

export const clearDataPersonNotFound: () => PersonAction = () => ({
  type: personTypes.PERSON_INFO_NOT_FOUND_REGISTER_CLEAR_DATA,
  payload: null,
})

export const actSetBeneficiaryInfo = (data, onOK) => async (dispatch) => {
  dispatch({
    type: personTypes.SET_BENEFICIARY_INFO,
    payload: data,
  })
  onOK && onOK()
}

export const actCleanBeneficiaryInfo: () => PersonAction = () => ({
  type: personTypes.CLEAN_BENEFICIARY_INFO,
  payload: null,
})
