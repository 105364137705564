// @flow
import React, { useState } from "react"
import { Dropdown } from "react-bootstrap"
import SimpleBar from "simplebar-react"
import ButtonCustom from "./Button/ButtonCustom"
import avatarDefault from "../assets/images/avatar-default.svg"
import { CgChevronRightR } from "react-icons/cg"
import { useHistory } from "react-router"
import { useGenerlsForms } from "../hooks/useGenerlsForms"

// notifiaction continer styles
const notificationContainerStyle = {
  display: "none",
}

const notificationShowContainerStyle = {
  height: "150px",
  width: "430px",
}

const NotificationDropdown = (props) => {
  const { setShow } = props
  const history = useHistory()
  const [notificationContentStyle, setNotificationContentStyles] = useState(
    notificationContainerStyle
  )

  const { handleGotoForm } = useGenerlsForms()

  /*
   * toggle notification-dropdown
   */
  const toggleDropdown = () => {
    setNotificationContentStyles(
      notificationContentStyle === notificationContainerStyle
        ? notificationShowContainerStyle
        : notificationContainerStyle
    )
    setShow(!props.dropdownOpen)
  }

  return (
    <Dropdown show={props.dropdownOpen} onToggle={toggleDropdown}>
      <Dropdown.Toggle
        id="dropdown-notification"
        className="nav-link dropdown-toggle icon-toggle "
      />
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-animated">
        <div onClick={toggleDropdown}>
          <div className="dropdown-item noti-title">
            <h5 className="m-0">Lista miembros hogar</h5>
          </div>
          <SimpleBar className="simple-bar h-auto" style={notificationShowContainerStyle}>
            {props.data &&
              props.data.map((item, i) => (
                <div key={i} className="d-flex align-items-center pt-1 pb-1 p-3">
                  <img
                    src={item.photoUrl ? item.photoUrl : avatarDefault}
                    alt=""
                    className="me-2 rounded-circle object-cover"
                    width="50px"
                    height="50px"
                  />
                  <p className="m-0">{`${item.name} ${item.lastName}`}</p>
                </div>
              ))}
          </SimpleBar>
          <div className="d-flex flex-wrap justify-content-between">
            <ButtonCustom
              variant="outline"
              className="underline w-50 text-center d-flex justify-content-center text-primary-500 font-bold"
              onClick={() => history.push("/services-pro/member-list")}
            >
              Lista completa
            </ButtonCustom>
            <ButtonCustom
              onClick={handleGotoForm}
              startIcon={<CgChevronRightR className="me-2" />}
              className="w-50"
              variant="primary"
            >
              Continuar al formulario
            </ButtonCustom>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NotificationDropdown
