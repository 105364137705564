// @flow
const groupByFields = (array, f) => {
  var groups = {}
  array.forEach((o) => {
    var group = JSON.stringify(f(o))
    groups[group] = groups[group] || []
    groups[group].push(o)
  })
  return Object.keys(groups).map((group) => {
    return groups[group]
  })
}

const transformDataToSelectCustom = (array, uppercase = true, addCheck = false) => {
  let data = []
  if (array) {
    data = array.map((obj) => {
      const response = {
        value: obj.id,
        label: obj.name ? (uppercase ? obj.name.toUpperCase() : obj.name) : "ERR NAME",
      }
      if (addCheck) response["checked"] = false;
      if ("active" in obj) response["active"] = obj.active
      return response
    })
  }
  return data
}

const arrayMove = (arr, old_index, new_index) => {
  const valueArray = [...arr]
  if (new_index >= valueArray.length) {
    var k = new_index - valueArray.length + 1
    while (k--) {
      valueArray.push(undefined)
    }
  }
  valueArray.splice(new_index, 0, valueArray.splice(old_index, 1)[0])
  return valueArray // for testing
}

const transformDataToSelectCustomCheck = (array, uppercase = true) => {
  let data = []
  if (array) {
    data = array.map((obj) => {
      const response = {
        value: obj.id,
        label: obj.name ? (uppercase ? obj.name.toUpperCase() : obj.name) : "ERR NAME",
      }
      if ("active" in obj) response["active"] = obj.active
      response["checked"] = false
      return response
    })
  }
  return data
}

const transformDataToSelectCustomU = (array) => {
  let data = []
  if (array)
    data = array.map((obj) => ({
      value: obj.id,
      label: obj.name ? obj.name.toUpperCase() : "ERR NAME",
    }))
  return data
}

const transformDataToSelectCustomDepartments = (array) => {
  const data = array.map((obj) => {
    return { value: obj.departmentCode, label: obj.department }
  })
  return data
}

const transformDataToSelectCustomCities = (array) => {
  const data = array.map((obj) => {
    return { value: Number(obj.cityCode), label: obj.city }
  })
  return data
}

const transformDataToSelectTransport = (array) => {
  const data = array.map((obj) => {
    return { value: obj.id, label: obj.description }
  })
  return data
}

const transformDataToSelectTypeTransport = (array) => {
  const data = array.map((obj) => {
    return { value: obj.id, label: obj.mode }
  })
  return data
}

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => (obj[key] == null || obj[key] === "") && delete obj[key])
}

const transformDataToSelectCustomEntities = (array) => {
  let data = []
  if (array)
    data = array.map((obj) => ({
      value: obj.id,
      label: obj.name,
      entityType: obj.entityType,
    })).filter((e) => e.entityType !== "ADMIN")
  return data
}
export {
  groupByFields,
  transformDataToSelectCustom,
  removeEmpty,
  transformDataToSelectCustomDepartments,
  transformDataToSelectCustomCities,
  transformDataToSelectCustomU,
  transformDataToSelectTransport,
  transformDataToSelectTypeTransport,
  transformDataToSelectCustomCheck,
  arrayMove,
  transformDataToSelectCustomEntities,
}
