// @flow
import Routes from "./routes/Routes"
import Store from "./redux/store"
import { PersistGate } from "redux-persist/integration/react"
import "./assets/scss/OIM.scss"
import { ErrorBoundary } from "./hoc/ErrorBoundary"
import useInterceptor from "./config/interceptor"

const App = () => {
  useInterceptor(Store)

  return (
    <PersistGate persistor={Store.persistor} loading={false}>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </PersistGate>
  )
}

export default App
